import React, { useMemo, useState, useRef, cloneElement, ReactElement, ComponentProps } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel, GridSortItem, GridSortModel, GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF as Column } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import Typography from '_shared/Typography'

import DownloadBulkDialog from '_core/components/dialogs/DownloadBulk'
import Download from '_core/components/Download'
import ExpandableDetails from '_core/components/ExpandableDetails'
import { Controller as FiltersController, CompaniesFiltersType } from '_core/components/filters/Companies'
import { DataGrid, GridTypes } from '_core/components/grid'
import GridHeaderButton from '_core/components/GridHeaderButton'
import GridHeadingButtonsContent, { GridHeadingButtonType } from '_core/components/GridHeadingButtons'
import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import { Narrow, useWide, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'
import SidepanelLink from '_core/components/SidepanelLink'
import { sortMap, getSortModels } from '_core/components/sort'
import Sort, { CompaniesSortProps, Controller as SortController } from '_core/components/sort/Companies'
import StatsContent from '_core/components/StatsContent'
import TagsGroup from '_core/components/TagsGroup'
import Widget from '_core/components/Widget'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useCompaniesListPayloads, { CompanyItem } from '_core/hooks/useCompaniesListPayloads'
import useDialog from '_core/hooks/useDialog'
import useDownloadBulk, { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSelectedInfoHandler from '_core/hooks/useSelectedInfoHandler'
import { groupTags } from '_core/hooks/useTagsManager'

import { checkOutlook } from '_core/helpers/outlook'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles<{ filtersOpened: boolean; filtersIconShown: boolean }>()((theme, { filtersOpened, filtersIconShown }) => ({
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: filtersOpened || !filtersIconShown ? 'calc(100% - 39px)' : 'calc(100% - 117px)',
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened || !filtersIconShown ? 39 : 39 * 3,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  }
}))

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<CompaniesFiltersType, 'opened' | 'isContributor' | 'interactionsFiltersShown' | 'disabled' | 'toggleOpen'>
  sortProps: Pick<CompaniesSortProps, 'value' | 'update' | 'items' | 'toggleExclude' | 'excludeEmpty'>
  viewProps: {
    viewMode?: ViewModeType
    updateViewMode: (val: ViewModeType) => void
  }
  searchPlaceholder: string
}

export const GridHeadingButtons = () => {
  const { isAdminOrCurator } = useAdminOrCuratorCheck()

  const actions: GridHeadingButtonType[] = [
    {
      label: 'Add company',
      icon: ['far', 'user-plus'],
      link: `${Paths._companies}/add`
    },
    {
      label: 'Upload companies',
      icon: ['far', 'cloud-arrow-up'],
      link: `${Paths._companies}/upload`,
      condition: !!isAdminOrCurator
    }
  ]

  return <GridHeadingButtonsContent actions={actions.filter((action) => (typeof action.condition === 'boolean' ? action.condition : true))} />
}

export const Heading = ({ sortProps, filtersProps, viewProps, searchPlaceholder, filters }: HeadingProps) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { viewMode, updateViewMode } = viewProps
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, isContributor, interactionsFiltersShown, disabled } = filtersProps
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [sortCollapsed, setSortCollapsed] = useState(true)
  const filtersIconShown = !(isContributor && !interactionsFiltersShown)
  const { classes } = useStyles({ filtersOpened, filtersIconShown })

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  const toggleView = () => updateViewMode(viewMode === 'collapsed' ? 'expanded' : 'collapsed')

  return (
    <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
      <div ref={anchorRef}>
        <Box display="flex">
          <SearchInput disabled={filtersProps.disabled} placeholder={searchPlaceholder} variant="collapsed" opened wrapperClassName={classes.input} />
          <Box className={classes.icons}>
            <IconButton
              hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
              onClick={toggleView}
              size="small"
              icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
              className={classes.displayVariant}
              disabled={disabled}
            />
            <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} className={classes.displayVariant} disabled={disabled} />
            {filtersIconShown && <FiltersController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />}
          </Box>
        </Box>
      </div>
      {cloneElement(filters, { anchorEl: anchorRef.current })}
      <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
    </Widget>
  )
}

export type CompaniesListProps = {
  items: CompanyItem[]
  loading: boolean
  enableDownloading: boolean | null
  total: number
  updateSort: (val: ScoreType | StatSortType) => void
  sort?: ScoreType | StatSortType
  onResize: GridTypes['onResize']
  setPageSize: GridTypes['setPageSize']
  paging: GridTypes['paging']
  sortByField?: GridSortItem['field']
  forceNarrow?: boolean
  viewMode?: ViewModeType
  columns: GridColDef[]
  downloadAllControl: ReactElement
}

export const DownloadAll = ({
  download,
  ...props
}: {
  download: { fileName: string; requestBinary: (data: DownloadBulkParams, excludeFormerEmployees: boolean) => Promise<Blob | undefined> }
} & Pick<CompaniesListProps, 'total' | 'loading'>) => {
  const [excludeFormerEmployees, setExcludeFormerEmployees] = useState<boolean>(false)
  const {
    register,
    getValues,
    errors,
    handleSubmit: downloadSubmit
  } = useDownloadBulk({
    fileName: download.fileName,
    requestBinary: (data: DownloadBulkParams) => download.requestBinary(data, excludeFormerEmployees)
  })

  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  const closeDlg = () => {
    setExcludeFormerEmployees(false)
    closeDialog()
  }

  const handleSubmit = (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    e?.preventDefault()
    closeDlg()
    return downloadSubmit()
  }

  return (
    <>
      <DownloadBulkDialog.TriggerEl open={openDialog} disabled={props.loading} />
      <DownloadBulkDialog
        totalRows={props.total}
        register={register}
        getValues={getValues}
        errors={errors}
        handleSubmit={handleSubmit}
        isOpened={isDialogOpened}
        close={closeDlg}
      >
        <Checkbox label="Exclude former employees" checked={excludeFormerEmployees} onChange={(e) => setExcludeFormerEmployees(e.target.checked)} />
      </DownloadBulkDialog>
    </>
  )
}

const initGridState = {
  pinnedColumns: { left: [Column.field, 'name'], right: [Column.field, 'score'] }
}

const CompaniesList = (props: CompaniesListProps) => {
  const upSmall = useWide('sm')
  const wide = useWide()

  const { selected, selectedIDs, selectedNames, handleSelectedInfo } = useSelectedInfoHandler('companies')
  const { queryParams } = useSearchQuery<CompaniesPageParams>()
  const { sortByField = 'score', viewMode, enableDownloading } = props
  const { withTagsList } = useCompaniesListPayloads(props.items)
  const isExpandedView = viewMode === 'expanded'

  const visibleStatsContent =
    !(viewMode === 'collapsed' && sortByField === 'score') && !!props.columns.find(({ field }) => field === 'lastInboundMsg')

  const items = useMemo(
    () =>
      props.items?.map((company) => {
        const tags = withTagsList?.find((item) => item.identifier === company.id)?.tags
        const visibleTagsContent = !!tags?.length

        const showAllTagsLink = `${Paths._companies}/${company.id}/tags?name=${company.name}`
        const editTagsLink = `${Paths._companies}/${company.id}/tags/edit?name=${company.name}`

        return {
          ...company,
          byline3: (
            <ExpandableDetails
              isExpandedView={isExpandedView}
              collapsedSizes={{
                contentStart: visibleTagsContent ? 32 : 0,
                content: visibleStatsContent ? 26 : 0
              }}
              contentStart={
                visibleTagsContent && (
                  <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                    items={tags}
                    tagComponent={InternalTagPopover}
                    renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                      <ExtraTagsPopover
                        triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                        items={groupTags(tags)}
                      />
                    )}
                    max={upSmall ? 4 : 2}
                    title={
                      isExpandedView ? (
                        <Typography color="text.secondary" semiBold>
                          Tags
                        </Typography>
                      ) : null
                    }
                    {...(wide
                      ? {
                          addTagTriggerEl: <AddTagTriggerEl link={editTagsLink} hasAny={!!tags?.length} />
                        }
                      : {})}
                    showIcon={isExpandedView}
                  />
                )
              }
              content={
                visibleStatsContent && (
                  <StatsContent
                    lastInbound={company.lastInbound}
                    lastOutbound={company.lastOutbound}
                    lastMeeting={company.lastMeeting}
                    nextFutureMeeting={company.nextFutureMeeting}
                    isExpandedView={isExpandedView}
                    sortByField={sortByField}
                  />
                )
              }
            />
          ),
          tags,
          showAllTagsLink,
          editTagsLink,
          variant: 'expandable'
        }
      }),
    [props.items, viewMode, sortByField, withTagsList, visibleStatsContent, upSmall]
  )

  const updateSort = (model: GridSortModel) => {
    if (model?.length && queryParams.sort) {
      const { field, sort: newSort } = model[0]
      if (newSort && queryParams.sort !== sortMap[field][newSort]) {
        const sort = sortMap[field][newSort]
        props.updateSort(sort)
      }
    }
  }

  const models: GridSortModel = useMemo(() => getSortModels(props.sort), [props.sort])

  const handleSelect = (selectionModel: GridSelectionModel) => {
    handleSelectedInfo(items, selectionModel as string[])
  }

  const DownloadSelected = <Download disabled={!selected.length} leadsList={selected} leadType="Company" />

  const AddTags = (
    <SidepanelLink
      linkProps={{
        to: selected.length
          ? selected.length === 1
            ? mergeUrlWithParams(`${Paths._companies}/${selectedIDs[0]}/tags/edit`, {
                name: selectedNames?.join(', ')
              })
            : mergeUrlWithParams(`${Paths._companies}/add-tags`, {
                keys: selectedIDs?.join(','),
                names: selectedNames?.join(', ')
              })
          : ''
      }}
      sidepanel={true}
    >
      <GridHeaderButton disabled={!selected.length} icon={['far', 'tag']} fontSize={21} hint={`${selected.length === 1 ? 'Edit' : 'Add'} tags`} />
    </SidepanelLink>
  )

  const Merge = (
    <SidepanelLink
      linkProps={{
        to: selected.length
          ? mergeUrlWithParams(`${Paths._merge}/companies`, {
              ids: selectedIDs?.join(',')
            })
          : ''
      }}
      sidepanel={true}
    >
      <GridHeaderButton disabled={!selected.length} icon={['far', 'merge']} hint={`Merge ${selected.length === 1 ? '' : 'companies'}`} />
    </SidepanelLink>
  )

  const controls =
    typeof enableDownloading === 'boolean'
      ? [
          { control: props.downloadAllControl, condition: enableDownloading },
          { control: DownloadSelected, condition: enableDownloading },
          { control: AddTags },
          { control: Merge }
        ]
          .filter((item) => (typeof item.condition === 'boolean' ? item.condition : true))
          .map((item) => item.control)
      : []

  const itemVariant = isExpandedView ? 'card' : 'list'

  return (
    <>
      <Wide forceNarrow={props.forceNarrow}>
        <DataGrid
          rows={items}
          columns={props.columns}
          controls={controls}
          sortModel={models.filter((m) => m.field === sortByField)}
          setSortModel={updateSort}
          onSelect={handleSelect}
          loading={props.loading}
          setPageSize={props.setPageSize}
          checkboxSelection={!!controls.length}
          paging={props.paging}
          total={props.total}
          initialState={initGridState}
        />
      </Wide>
      <Narrow forceNarrow={props.forceNarrow}>
        <Repeater direction="vertical" variant={itemVariant} component={ProfileItem} skeleton={{ size: 20, loading: props.loading }} items={items} />
      </Narrow>
    </>
  )
}

export default CompaniesList
