import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { ActivitiesListItem, ActivityDataType } from '_core/components/ActivitiesList'

import { request } from 'utils/fetchUtils'
import { transformBody } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

export type ActivityStatsMonth = { year: number; month: number; minDay: number; maxDay: number }

const useActivitiesStats = (
  plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
  groupByMd5s: string[],
  months: ActivityStatsMonth[]
) => {
  const { teamContextValue } = useContext(TeamContext)
  const [{ loading, stats }, setData] = useState<{ loading: boolean; stats?: Pick<ActivitiesListItem, 'inbound' | 'outbound' | 'meetings'>[] }>({
    loading: false
  })

  const abortRef = useRef<null | AbortController>(null)

  const clearStats = () => {
    setData({ loading: false })
  }

  const getStats = useCallback(
    async (
      plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
      groupByMd5s: string[],
      months: { year: number; month: number; minDay: number; maxDay: number }[]
    ) => {
      if (plds && months.length) {
        const meetingsPayload = {
          periods: months.map(({ year, month, minDay, maxDay }) => ({
            from: getLocal({ year, month: month - 1, day: minDay })
              .startOf('day')
              .toISOString(),
            until: getLocal({ year, month: month - 1, day: maxDay })
              .endOf('day')
              .toISOString()
          })),
          ...(plds.meetings || {})
        }

        const localHoursShift = -(getLocal().utcOffset() / 60)

        const inboundPayload = {
          months,
          localHoursShift,
          ...(plds.inbound || {})
        }

        const outboundPayload = {
          months,
          localHoursShift,
          ...(plds.outbound || {})
        }

        const requests = [
          {
            url: '/histostats/meetings',
            payload: meetingsPayload
          },
          {
            url: '/histostats/emails',
            payload: inboundPayload
          },
          {
            url: '/histostats/emails',
            payload: outboundPayload
          }
        ]

        const abortController = new AbortController()
        abortRef.current = abortController

        const createRequest = ({ url, payload }: { url: string; payload: Record<string, any> }) => {
          return request<ActivitiesHistoStatsRespType>(`${url}?teamNumber=${teamContextValue.teamNumber}`, {
            method: 'POST',
            body: transformBody({
              ...payload,
              withMeetingTags: true,
              havingMeetingTags: '',
              lackingMeetingTags: '',
              skip: 0,
              take: 100
            }),
            ...(abortRef.current ? { signal: abortRef.current.signal } : {})
          })
        }

        const responses = await Promise.all(requests.map(createRequest))

        if (!responses.includes(undefined)) {
          const [meetings, inbound, outbound] = responses.reduce(
            (acc, data = [], idx) => {
              data.forEach(({ results }, k) => {
                acc[idx % requests.length] = {
                  ...acc[idx % requests.length],
                  ...results.reduce(
                    (dAcc, d) => {
                      const accPer3 = acc[idx % requests.length]?.[d.groupedBy]

                      if (!accPer3) {
                        return {
                          ...dAcc,
                          [d.groupedBy]: {
                            byIndex: { [k]: d.count },
                            count: d.count,
                            groupedBy: d.groupedBy
                          }
                        }
                      }

                      return {
                        ...dAcc,
                        [d.groupedBy]: {
                          byIndex: { ...accPer3.byIndex, [k]: d.count },
                          count: accPer3.count + d.count,
                          groupedBy: d.groupedBy
                        }
                      }
                    },
                    {} as { [groupedBy: string]: ActivityDataType }
                  )
                }
              })
              return acc
            },
            [] as { [groupedBy: string]: ActivityDataType }[]
          )

          const result = groupByMd5s.map((key) => ({
            meetings: meetings[key],
            inbound: inbound[key],
            outbound: outbound[key]
          })) as ActivitiesListItem[]

          return result
        }
      }
    },
    [teamContextValue.teamNumber]
  )

  useEffect(() => {
    return () => {
      clearStats()
      if (abortRef.current) {
        abortRef.current.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [months])

  useEffect(() => {
    if (plds && groupByMd5s.length && months) {
      ;(async () => {
        setData({ loading: true })
        const stats = await getStats(plds, groupByMd5s, months)
        if (stats) {
          setData({ loading: false, stats })
        }
      })()
    }
  }, [getStats, groupByMd5s, plds, months])

  return { loading, stats, clearStats }
}

export default useActivitiesStats
