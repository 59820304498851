/* eslint-disable react/jsx-key */
import React, { ComponentProps, Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Moment as MomentType } from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AlertWidget } from '_pages/companies/[id]/touchpoints'

import Avatar from '_shared/Avatar'
import { Button } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import Empty from '_core/components/Empty'
import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import HelpLinkButton from '_core/components/HelpLinkButton'
import IdentifierLabel from '_core/components/IdentifierLabel'
import { Interaction, getTime } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import Relationship from '_core/components/Relationship'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelationSummary from '_core/components/TouchpointRelationSummary'
import Widget from '_core/components/Widget'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import ContactsWidget from '_core/components/widgets/Contacts'
import ExternalKeysWidget from '_core/components/widgets/ExternalKeys'
import IntroducersWidget from '_core/components/widgets/Introducers'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import { CSuiteType } from '_core/hooks/useCSuite'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { CompaniesType as SimilarCompaniesType } from '_core/hooks/useSimilarCompanies'

import { stringifyUrl } from '_core/helpers/browser'
import { sum } from '_core/helpers/numbers'
import { addMissedProtocol } from '_core/helpers/string'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { ellipsisText, getUTC } from 'utils/Utils'

import { widgetSubTitle } from 'AppTheme'

import Paths from 'Paths'

type ProfileWidgetsType = {
  loading: boolean
  teamNumber: number
  interactions: { [p: string]: any } | null | undefined
  marketData: { [p: string]: any } | null
  similar: SimilarCompaniesType | null
  setSimilar: Dispatch<SetStateAction<any>>
  cSuite: CSuiteType | null
  setCSuite: Dispatch<SetStateAction<any>>
  showInteractionsInProfile: boolean
  showActivityStats: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  marketDataIntegration: MarketDataIntegration | undefined
}

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: theme.spacing(2),
      marginBottom: `-${theme.spacing(2)}`
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 14
  },
  tagsWrapper: {
    overflow: 'hidden',
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

const InteractionsWidget = ({ interactions, ...props }: Pick<ProfileWidgetsType, 'interactions' | 'loading'> & CompanyType) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  const { data: items, errorMessage } = interactions || {}
  const loading = props.loading || !interactions

  return (
    <Widget>
      <Heading
        underlined
        title="Interactions"
        icon={['far', 'envelope']}
        count={0}
        action={
          <Box flex={1}>
            <InteractionsPrivacyMsg />
          </Box>
        }
        link={!loading && items?.length ? `${Paths._companies}/${props.CompanyMd5}/interactions` : undefined}
      />
      {errorMessage && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      )}
      {!errorMessage && (
        <>
          {(!!items?.length || loading) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={items
                  ?.map((item: { [key: string]: any }) => ({
                    time: getTime(item.receivedDateTime),
                    title: item.rawSubject || '(No subject)',
                    byline: item.body,
                    byline2: (
                      <NameLink
                        variant="light"
                        url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                        name={item.senderName}
                      />
                    ),
                    dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} />,
                    sidepanel: true,
                    link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                    component: 'div'
                  }))
                  .slice(0, 5)}
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!items?.length && !loading && (
            <Empty
              className={classes.empty}
              subTitle={`You have not exchanged any email messages with ${props.CompanyNameText || props.BestUrlText}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
            />
          )}
        </>
      )}
    </Widget>
  )
}

const CSuiteWidget = ({
  cSuite,
  setCSuite,
  teamNumber,
  ...props
}: Pick<ProfileWidgetsType, 'cSuite' | 'setCSuite' | 'teamNumber' | 'loading' | 'marketData'> & CompanyType) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.BestUrlText && !cSuite && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: `/companies/${props.BestUrlText}/cSuite?titlecase=true&teamNumber=${teamNumber}` })
          if (res) setCSuite(res)
        } catch (e: any) {
          setCSuite({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching cSuite', e)
        }
      })()
    }
  }, [props.BestUrlText, cSuite, teamNumber])

  const items = cSuite?.data?.length
    ? cSuite.data.slice(0, 5).map((person) => {
        return {
          name: person.full_name,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.full_name}`, {
                email: person.work_email,
                company: person.job_company_name
              })
        }
      })
    : []

  const load = loading || props.loading
  const showEmptyWidget = error || (!load && !cSuite?.data?.length)

  return (
    <Widget>
      <Heading
        underlined
        title="C Suite"
        icon={['fas', 'user-tie']}
        count={cSuite?.total}
        link={cSuite?.total && !load ? `${Paths._companies}/${id}/c-suite?name=${props.CompanyNameText || props.BestUrlText}` : undefined}
        sidepanel
      />
      {!showEmptyWidget && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: load }} items={items} />}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={error || 'No records were found'}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const SimilarCompaniesWidget = ({
  similar,
  setSimilar,
  teamNumber,
  ...props
}: Pick<ProfileWidgetsType, 'similar' | 'setSimilar' | 'teamNumber' | 'loading' | 'marketData'> & CompanyType) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch<{ [key: string]: any }>()
  const { classes } = useStyles()

  const companyName = props.CompanyNameText || props.BestUrlText

  const load = loading || props.loading
  const { employee_count, industry, location, type, website } = props.marketData || {}
  const enoughFilters = props.marketData && (website || props.BestUrlText)
  const showEmptyWidget = error || (enoughFilters && !loading && !similar?.data?.length) || (props.marketData && !enoughFilters)

  const initParams = useMemo(
    () => ({
      website: website || props.BestUrlText,
      minimumEmployeeCount: employee_count ? Math.round(Number(employee_count) / 2).toString() : '',
      maximumEmployeeCount: employee_count ? Math.round(Number(employee_count) * 1.5).toString() : '',
      industries: industry ? [industry] : [],
      countries: [location?.country || 'United States'],
      companyType: type || 'Private',
      teamNumber: teamNumber.toString(),
      titlecase: 'true'
    }),
    [props.BestUrlText, website, employee_count, industry, location?.country, type, teamNumber]
  )

  useEffect(() => {
    if (enoughFilters && !similar && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: mergeUrlWithParams('/companies/similarCompanies', initParams) })
          if (res) setSimilar(res)
        } catch (e: any) {
          setSimilar({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching similar companies', e)
        }
      })()
    }
  }, [enoughFilters, similar, teamNumber])

  const items = similar?.data
    ? similar.data.map((company) => {
        return {
          name: company.name,
          score: company.companyMd5 ? company.score : undefined,
          sidepanel: true,
          logoUrl: company.website,
          link: company.companyMd5
            ? `${Paths._companies}/${company.companyMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
                website: company.website
              }),
          byline: company.website,
          byline2: company.industry
        }
      })
    : []

  return (
    <Widget>
      <Heading
        underlined
        title="Similar companies"
        icon={['fas', 'diagram-venn']}
        count={showEmptyWidget ? 0 : similar?.total}
        link={similar?.total && id && !showEmptyWidget ? `${Paths._companies}/${id}/similar?clearSearch=true&name=${companyName}` : undefined}
        sidepanel
      />
      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={
            error ||
            (enoughFilters ? 'No records were found matching your search' : 'Not enough market data is available for an effective search filter.')
          }
          action={
            <Box display="flex" justifyContent="center">
              <SidepanelLink
                linkProps={{ to: `${Paths._companies}/${id}/similar?openedFilters=true&clearSearch=true&name=${companyName}` }}
                sidepanel={true}
              >
                <Button variant="link" bold={false}>
                  {enoughFilters ? 'Change' : 'Add'} filters
                </Button>
              </SidepanelLink>
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const RelationshipsWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()

  const items = props.Employees?.data.filter((item) => item.KnowerNameText) || []

  return (
    <Widget>
      <Heading
        underlined
        title="Key relationships"
        icon={['far', 'star']}
        count={props.loading ? undefined : 0}
        link={items.length ? `${Paths._companies}/${props.CompanyMd5}/relationships` : ''}
      />
      {(!!items.length || props.loading) && (
        <Repeater
          direction="vertical"
          component={Relationship}
          skeleton={{ size: 3, loading: props.loading }}
          items={items.slice(0, 3).map((rel) => ({
            name1: rel.PersonNameText || rel.ContactBestEmail,
            name2: rel.KnowerNameText,
            score: rel.UserKnowsPersonScore,
            userKey1: rel.ContactBestEmail
          }))}
        />
      )}
      {!items.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no key relationships with ${props.CompanyNameText || props.BestUrlText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'star']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const ActivitiesStatsWidget = ({
  md5 = '',
  isDetailedActivityFromStatsWidgetVisible,
  onChange
}: {
  md5?: string
  isDetailedActivityFromStatsWidgetVisible: boolean
  onChange: (params: ActivityStatsPageParams) => void
}) => {
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { stackColumns = 'false', from: fromUTC, to: toUTC } = queryParams

  const statsPayloads = useCompanyActivitiesPayloads(md5)

  const chartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { stats, clearStats } = useActivitiesStats(
    md5 ? statsPayloads : null,
    useMemo(() => [md5], [md5]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  useEffect(() => {
    if (!md5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5])

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    onChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    onChange({ stackColumns: `${!JSON.parse(stackColumns)}` })
  }

  return (
    <ActivityStatsWidget
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      stats={stats}
      stack={stackColumns === 'true'}
      detailsLink={`${Paths._companies}/${md5}/activities`}
      seeAllLink={`${Paths._companies}/${md5}/activityStats`}
      updatePeriod={updatePeriod}
      toggleStack={toggleStack}
      {...chartData}
    />
  )
}

const TouchpointsWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()

  const stats =
    !props.loading && props.Stats
      ? Object.getOwnPropertyNames(props.Stats).filter(
          (k) =>
            ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg', 'NextFutureMeeting'].indexOf(
              k
            ) > -1
        )
      : []

  return (
    <Widget>
      <Heading
        underlined
        title="Key touch points"
        icon={['far', 'handshake']}
        count={props.loading ? -1 : 0}
        link={!props.loading && stats.length ? `/companies/${props.CompanyMd5}/touchpoints` : ''}
      />
      {(!!stats.length || props.loading) && (
        <Repeater component={TitleDescription} skeleton={{ size: 3, loading: props.loading }} items={TouchpointRelationSummary(props.Stats)} />
      )}
      {!stats.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${props.CompanyNameText || props.BestUrlText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const AliasesDomainsWidget = (props: Pick<ProfileWidgetsType, 'loading' | 'teamNumber'> & CompanyType) => {
  const {
    classes: { heading }
  } = useStyles()
  const { id } = useParams<{ id: string }>()

  const companyName = props.CompanyNameText || props.BestUrlText

  const submitInvalid = async (identity: string, value: number) => {
    return post(mergeUrlWithParams('/companies/disqualify', { teamNumber: `${props.teamNumber}` }), {
      identity,
      reason: nameReason[value]
    })
  }

  return (
    <Widget>
      <Heading
        underlined
        title="Aliases and domains"
        count={props.loading ? -1 : sum(props.Aliases?.total_item_count, props.Urls?.total_item_count)}
        icon={['far', 'fingerprint']}
        link={!props.loading ? `${Paths._companies}/${props.CompanyMd5}/aliases` : undefined}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: props?.Aliases?.data?.length ? -8 : 0 }}
        >
          Aliases
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Aliases?.data?.slice(0, 2).map((name) => ({
            name: companyName,
            identity: name.CoNameAlias,
            icon: ['far', 'building'],
            type: 'CompanyName',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`,
            auditLink: `${Paths._companies}/${id}/audit`
          })) || []}
          empty="No aliases were found"
        />

        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 12, marginBottom: props?.Urls?.data?.length ? -8 : 0 }}>
          Domains
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Urls?.data?.slice(0, 2).map((url) => ({
            name: companyName,
            identity: url.CoUrl,
            icon: ['far', 'globe'],
            externalLink: addMissedProtocol(url.CoUrl),
            type: 'DomainUrl',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/domainSources/${url.CoUrl}`,
            auditLink: `${Paths._companies}/${id}/audit`
          })) || []}
          empty="No domains were found"
        />
      </Box>
    </Widget>
  )
}

const MarketDataWidget = (props: Pick<ProfileWidgetsType, 'marketData'> & { name: string }) => {
  const { id: companyId } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const theme = useTheme()
  const sidepanel = useMediaQuery(theme.breakpoints.up('sidepanel'))

  const { tags, summary, id, website, founded, location, employee_count, industry } = props.marketData || {}
  const mocked = new Array(3).fill('')
  const noData = props.marketData && !id
  const noWidgetData = id && !tags?.length && !summary
  const notEnoughData = noWidgetData && !website && !founded && !location && !employee_count && !industry

  return (
    <Widget>
      <Heading
        underlined
        title="Details"
        icon={['fas', 'chart-column']}
        count={0}
        action={
          <Box flex={1}>
            <HelpLinkButton
              hint="Learn more about market data"
              to={{ pathname: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data' }}
              size="small"
              disablePY
            />
          </Box>
        }
        link={!noData && !notEnoughData && props.name ? `${Paths._companies}/${companyId}/market-data?name=${props.name}` : ''}
        sidepanel
      />

      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No tag or summary info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      <Box mb={{ md: 2 }}>
        {!props.marketData && (
          <Box>
            {mocked.map((el, i) => (
              <Skeleton condition={!props.marketData} height="23px" width="100%" key={i} />
            ))}
          </Box>
        )}

        {summary && <Typography style={{ paddingBottom: '8px' }}>{ellipsisText(summary, 150)}</Typography>}

        {(!props.marketData || !!tags?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={sidepanel ? 2 : 1}
            items={!props.marketData ? mocked : tags}
            title={<Typography bold>Tags</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={!props.marketData ? mocked : tags}
                      title="Tags"
                      name={props.name}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
      </Box>
    </Widget>
  )
}

const Profile = ({
  showInteractionsInProfile,
  showActivityStats,
  marketDataIntegration,
  marketData,
  similar,
  setSimilar,
  cSuite,
  setCSuite,
  teamNumber,
  isDetailedActivityFromStatsWidgetVisible,
  interactions,
  CrmSourceKeys,
  ...props
}: ProfileWidgetsType & CompanyType) => {
  const { id } = useParams<{ id: string }>()
  const theme = useTheme()
  const wideStrict = useWide('lg')

  const companyName = props.CompanyNameText || props.BestUrlText

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()
  const { queryParams } = useSearchQuery<PersonProfilePageParams>()
  const { stackColumns } = queryParams

  useEffect(() => {
    if (!props.loading && props.CompanyMd5) window.parent.postMessage('da_open', '*')
  }, [props.loading, props.CompanyMd5])

  const { enabled: enabledMarketData, showSimilarCompanies } = marketDataIntegration || {}

  const MarketDataWidgetEl = <MarketDataWidget marketData={marketData} name={companyName} />
  const ExternalKeysWidgetEl = CrmSourceKeys?.data?.length ? (
    <ExternalKeysWidget
      entity="company"
      loading={props.loading}
      keys={CrmSourceKeys.data.map((key) => ({ sourceId: key.SourceId, lastModified: key.LastModifiedDateTime }))}
      total={CrmSourceKeys.total_item_count}
      name={companyName}
    />
  ) : (
    <></>
  )

  const ContactsWidgetEl = (
    <ContactsWidget
      loading={props.loading}
      entityName={companyName}
      link={`${Paths._companies}/${props.CompanyMd5}/contacts`}
      total={props.Employees?.total_item_count}
      items={props.Employees?.data?.slice(0, 5).map((contact) => ({
        name: contact.PersonNameText || contact.ContactBestEmail,
        score: contact.WeKnowPersonScore,
        sidepanel: true,
        link: `${Paths._people}/${contact.PersonMd5}`,
        userKey: contact.ContactBestEmail
      })) || []}
    />
  )

  const IntroducersWidgetEl = (
    <IntroducersWidget
      loading={props.loading}
      entityName={companyName}
      link={`${Paths._companies}/${props.CompanyMd5}/introducers`}
      total={props.Introducers?.total_item_count}
      items={props.Introducers?.data.slice(0, 5).map((intro) => ({
        name: intro.IntroducerName,
        userKey: intro.IntroducerBestEmailAddressText,
        score: intro.IntroducerScore,
        link: `${Paths._relationships}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}/companies/${props.CompanyMd5}`,
        sidepanel: true
      })) || []}
    />
  )
  const CSuiteWidgetWidgetEl = <CSuiteWidget cSuite={cSuite} setCSuite={setCSuite} teamNumber={teamNumber} marketData={marketData} {...props} />
  const InteractionsWidgetEl = <InteractionsWidget interactions={interactions} {...props} />
  const SimilarCompaniesWidgetEl = (
    <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
  )
  const ActivitiesStatsWidgetEl = (
    <ActivitiesStatsWidget
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      md5={props.CompanyMd5}
      onChange={handleChange}
    />
  )
  const TouchpointsWidgetEl = <TouchpointsWidget {...props} />
  const RelationshipsWidgetEl = <RelationshipsWidget {...props} />
  const AliasesDomainsWidgetEl = <AliasesDomainsWidget teamNumber={teamNumber} {...props} />

  const columnsRows = [
    {
      condition: showInteractionsInProfile && showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[ContactsWidgetEl], [SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [SimilarCompaniesWidgetEl]] },
            { columns: [[CSuiteWidgetWidgetEl], [RelationshipsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, CSuiteWidgetWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [ContactsWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [CSuiteWidgetWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <UserSettings key={id} endpoint={!stackColumns && showActivityStats ? '/usersettings/activitiesstats' : ''} setInitial={setInitial}>
      <Wide>
        <Columns>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => {
              if (!Array.isArray(column)) {
                return (
                  <Column md={12} key={`${k}${i}`}>
                    {column}
                  </Column>
                )
              }
              return (
                <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                  {column.map((row, j) =>
                    Array.isArray(row) ? (
                      <Columns
                        key={`${k}${i}${j}`}
                        md={12}
                        minWidth={`calc(100% + ${theme.spacing(2)})`}
                        {...(!row.length ? { display: 'none' } : {})}
                      >
                        {row.map((r, y) => (
                          <Column md={6} key={`${k}${i}${j}${y}`}>
                            {r}
                          </Column>
                        ))}
                      </Columns>
                    ) : (
                      <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                    )
                  )}
                </Column>
              )
            })}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <AlertWidget {...props} />
        {showActivityStats && ActivitiesStatsWidgetEl}
        {enabledMarketData && MarketDataWidgetEl}
        {IntroducersWidgetEl}
        {ContactsWidgetEl}
        {showSimilarCompanies && (
          <>
            {CSuiteWidgetWidgetEl}
            {SimilarCompaniesWidgetEl}
          </>
        )}
        {showInteractionsInProfile && InteractionsWidgetEl}
        {TouchpointsWidgetEl}
        {RelationshipsWidgetEl}
        {AliasesDomainsWidgetEl}
        {ExternalKeysWidgetEl}
      </Narrow>
    </UserSettings>
  )
}
export default Profile
