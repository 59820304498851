import { useEffect, useRef, useState, useMemo, useCallback, memo, ReactElement, ChangeEvent } from 'react'

import { Box, ListItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { EntitiesList } from '_core/components/audit/AuditEntities'
import AuditColumns from '_core/components/audit/AuditLayout'
import TupleSources from '_core/components/audit/TupleSources'
import { useSidepanelWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import NextStepFrame from '_core/components/NextStepFrame'

import useAuditEntities from '_core/hooks/useAuditEntities'
import useAuditTuples from '_core/hooks/useAuditTuples'

const sourceType = (key: string) => {
  switch (key) {
    case 'CalendarEntries':
      return 'calendar'
    case 'ContactCards':
      return 'contacts'
    case 'MarketData':
      return 'market data'
    default:
      return 'data'
  }
}

const useStyles = makeStyles()((theme) => ({
  associations: {
    overflow: 'auto',
    height: 'calc(100% - 48px)'
  },
  wrapper: {
    flex: '1 1 auto',
    width: '100%',
    border: '1px rgba(0,0,0,0.12) solid',
    borderRadius: 10,
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    overflow: 'hidden'
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr auto'
  },
  sourceSubstrate: {
    margin: `${theme.spacing(1)} -${theme.spacing(1)} -${theme.spacing(2)} -${theme.spacing(1)}`,
    fontSize: 12,
    textAlign: 'center',
    background: theme.palette.background.darker,
    padding: theme.spacing(1)
  },
  source: {
    border: 0,
    padding: 0,
    background: 'transparent none',
    cursor: 'pointer',
    fontSize: 12,
    color: '#7d7d7d',
    wordBreak: 'break-all'
  },
  tuple: {
    width: '100%'
  }
}))

type AssociationItem = AuditAnomalousTuple & { uid: string }

type AssociationProps = {
  checked: boolean
  uid: string
  id1: string
  id1md5: string
  id2: string
  id2md5: string
  type: 'people' | 'companies'
  sources: AuditAnomalousTuple['sources']
  toggleCheck: AssociationsListProps['toggle']
  openSources: AssociationsListProps['openSources']
}

export const AssociationListItem = ({
  source,
  actions,
  toggleCheck,
  ...props
}: Pick<AssociationProps, 'checked' | 'id1' | 'id2'> & {
  toggleCheck: (e: ChangeEvent<HTMLInputElement>) => void
  source?: ReactElement
  actions?: ReactElement
}) => {
  const { classes, cx } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })

  return (
    <ListItem dense>
      <Checkbox
        checked={props.checked || false}
        onChange={toggleCheck}
        align="center"
        classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, classes.tuple) }}
        label={
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div style={{ fontSize: 13, textAlign: 'center', color: '#1B95BB', lineHeight: '24px' }}>
                <Button variant="link" bold={false} disablePY>
                  <Skeleton condition={!props.id1} width={120}>
                    {props.id1}
                  </Skeleton>
                </Button>
                <br />
                <Typography color="text.hint" component="span">
                  is associated with
                </Typography>
                <br />
                <Button variant="link" bold={false} disablePY>
                  <Skeleton condition={!props.id1} width={120}>
                    {props.id2}
                  </Skeleton>
                </Button>
              </div>
              {actions && <>{actions}</>}
            </div>
            {source && <>{source}</>}
          </div>
        }
      />
    </ListItem>
  )
}

const Association = memo((props: AssociationProps) => {
  const { classes } = useStyles()
  const source =
    props.sources.length > 0
      ? (() => {
          const firstSource = props.sources[0]
          if ('forQuickSplit' in firstSource) {
            return 'implied'
          }
          const sourceTypeTitle = firstSource?.sourceType ? sourceType(firstSource.sourceType) : ''
          const additionalSourcesCount =
            props.sources.length > 1 ? ` and other ${props.sources.length - 1} source${props.sources.length - 1 !== 1 ? 's' : ''}` : ''

          return `${firstSource?.userKey}${additionalSourcesCount}'s ${sourceTypeTitle}`
        })()
      : 'Unknown source'

  const toggleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    if (typeof checked === 'boolean') {
      props.toggleCheck(props.uid)
    }
  }

  const openSources = () => {
    props.openSources({ lowText: props.id2, highText: props.id1, sources: props.sources })
  }

  const Source = (
    <div className={classes.sourceSubstrate}>
      <button onClick={openSources} className={classes.source}>
        <Skeleton condition={!source} width={120}>
          {source}
        </Skeleton>
      </button>
    </div>
  )

  return <AssociationListItem checked={props.checked} id1={props.id1} id2={props.id2} toggleCheck={toggleCheck} source={Source} />
})

type AssociationsListProps = {
  items: AssociationItem[]
  openSources: (data: Pick<AuditAnomalousTuple, 'lowText' | 'highText' | 'sources'>) => void
  closeSources: () => void
  toggle: (uid: string) => void
  toggleAll: () => void
}

const AssociationsList = (props: AssociationsListProps) => {
  const { classes } = useStyles()

  const params = useParams<{ id: string }>()
  const tuplesListEl = useRef<HTMLDivElement>(null)

  const { items, toggle, toggleAll } = props
  const checked = items.filter(({ isActive }) => isActive)

  return (
    <AuditColumns.Column
      heading={
        <Typography>
          The following associations must be broken to account for your changes. Please uncheck any that you would like to keep and click NEXT.
        </Typography>
      }
    >
      <ListItem dense onClick={toggleAll}>
        <Checkbox
          checked={items.length === checked.length}
          label={
            <Typography variant="h4" semiBold>
              Associations
            </Typography>
          }
        />
      </ListItem>
      <div className={classes.associations} ref={tuplesListEl}>
        <Repeater
          direction="vertical"
          variant="homepage"
          component={Association}
          skeleton={{ size: 10, loading: false }}
          items={items.map((tuple) => ({
            checked: tuple.isActive,
            id: params.id,
            uid: tuple.uid,
            id1: tuple.highText || tuple.highMd5,
            id1md5: tuple.highMd5,
            id2: tuple.lowText || tuple.lowMd5,
            id2md5: tuple.lowMd5,
            type: 'people',
            toggleCheck: toggle,
            openSources: props.openSources,
            sources: tuple.sources
          }))}
        />
      </div>
    </AuditColumns.Column>
  )
}

const AuditTuples = (props: { next: () => void; back: () => void; reset: () => void }) => {
  const sidepanelWide = useSidepanelWide()
  const { activeIndex, setActive, valid, isDirty } = useAuditEntities()
  const { tuples = [], toggleSplit, toggleSplitAll } = useAuditTuples()
  const [tupleSources, setTupleSources] = useState<Pick<AuditAnomalousTuple, 'lowText' | 'highText' | 'sources'> | null>(null)

  const entities = useMemo(
    () =>
      valid
        .slice(1)
        .filter(({ identifiers }) => identifiers.length)
        .map(({ identifiers, ...ent }) => ent),
    [valid]
  )

  const items = tuples[activeIndex]

  const openSources = useCallback((data: Pick<AuditAnomalousTuple, 'lowText' | 'highText' | 'sources'>) => {
    setTupleSources(data)
  }, [])

  const closeSources = useCallback(() => {
    setTupleSources(null)
  }, [])

  useEffect(() => {
    if (entities[0]) {
      setActive(entities[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    closeSources()
  }, [activeIndex])

  const toggleAll = () => {
    toggleSplitAll(activeIndex)
  }

  const next = () => props.next()

  const back = () => props.back()

  return (
    <NextStepFrame
      next={
        <Button onClick={next} variant="text" color="secondary">
          Next
        </Button>
      }
      back={
        <Button onClick={back} variant="text" color="secondary">
          Back
        </Button>
      }
      reset={props.reset}
      isDirty={isDirty}
    >
      <AuditColumns
        entities={
          sidepanelWide ? (
            <Box p={2}>
              <EntitiesList items={entities} setActive={setActive} fullWidth />
            </Box>
          ) : null
        }
      >
        {tupleSources && <TupleSources {...tupleSources} close={closeSources} />}
        {!tupleSources && (
          <AssociationsList items={items} toggle={toggleSplit} toggleAll={toggleAll} openSources={openSources} closeSources={closeSources} />
        )}
      </AuditColumns>
    </NextStepFrame>
  )
}

export default memo(AuditTuples)
