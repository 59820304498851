import React, { PropsWithChildren, useEffect, MouseEvent, forwardRef, ForwardedRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Popover, CircularProgress, Collapse, Fade, Theme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import { DisablePaddingType } from '_shared/buttons/Button'
import Checkbox from '_shared/forms/Checkbox'
import Radio from '_shared/forms/Radio'
import Typography from '_shared/Typography'

import Bar from '_core/components/Bar'
import { Narrow, Wide } from '_core/components/layout'
import { expandFilterOnboardingTarget } from '_core/components/onboarding/Filterbar'

import useOnboarding from '_core/hooks/useOnboarding'

import { updateQuerystring } from '_core/helpers/browser'

const useStyles = makeStyles<Partial<{ shift: number; barShown: boolean }>>()((theme: Theme, { shift, barShown }) => ({
  button: {
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid #f2f2f2',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 12
  },
  btnLabel: {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16
  },
  endIcon: {
    width: 13,
    height: 17,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    paddingBottom: theme.spacing(1),
    padding: `0px ${theme.spacing(2)}`,
    borderBottom: `1px solid #f2f2f2`,
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
      borderBottom: `none`,
      minWidth: 194,
      minHeight: '100%'
    }
  },
  paper: {
    left: '0px !important',
    top: '0px !important',
    minWidth: '100%',
    minHeight: '100%'
  },
  topBlock: {
    zIndex: 1,
    background: theme.palette.background.light,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f2f2f2',
    position: 'fixed',
    left: 0,
    width: '100%',
    maxWidth: 'calc(100vw - 5px)',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      background: 'transparent',
      position: 'unset',
      marginRight: `-${theme.spacing(2)}`,
      marginLeft: `-${theme.spacing(2)}`,
      width: 'calc(100% + 32px)',
      paddingBottom: theme.spacing(2)
    },
    '& .MuiBox-root:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
      maxWidth: '50%',
      '& > .MuiTypography-root': {
        marginLeft: 'auto'
      }
    },
    '& .MuiBox-root:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  content: {
    marginTop: theme.spacing(7)
  },
  collapse: {
    height: `${barShown ? 'calc(100% - 28px)' : '100%'} !important`
  },
  wrapperInner: {
    flex: 1
  },
  bar: {
    justifyContent: 'flex-end'
  },
  shift: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      marginLeft: shift ? -shift : 0,
      flex: '1 0 auto'
    }
  }
}))

type Reset = {
  reset: () => Promise<void> | void
  disabled: boolean
}

type SkipReset = {
  reset?: never
  disabled?: never
}

type FilterProps = {
  opened: boolean
  toggleOpen?: () => void
  contentLoading: boolean
  className?: string
  hideBorder?: boolean
  total?: number
  anchorEl?: HTMLElement | null
  shift?: number
} & (Reset | SkipReset)

type TextController = {
  variant: 'text'
} & { [key in DisablePaddingType]?: never }

type IconController = {
  variant?: 'icon'
} & { [key in DisablePaddingType]?: boolean }

export const Controller = forwardRef(
  (
    props: { toggleOpen: (e: MouseEvent<HTMLElement>) => void; opened?: boolean; disabled?: boolean } & (TextController | IconController),
    ref?: ForwardedRef<any>
  ) => {
    const { classes, cx } = useStyles({})
    const { variant = 'icon', toggleOpen, opened = true, disabled, ...disablePaddings } = props

    if (variant === 'icon') {
      return (
        <IconButton
          ref={ref}
          disableRipple
          size="small"
          onClick={toggleOpen}
          icon={['far', opened ? 'times' : 'filter']}
          color={opened ? 'primary' : 'secondary'}
          disabled={disabled}
          {...disablePaddings}
        />
      )
    }

    return (
      <Button
        ref={ref}
        variant="text"
        disableRipple
        classes={{ root: cx(classes.button, classes.btnLabel), endIcon: classes.endIcon }}
        fullWidth
        onClick={toggleOpen}
        color={opened ? 'primary' : 'secondary'}
        endIcon={<FontAwesomeIcon icon={['far', opened ? 'times' : 'filter']} style={{ width: 14, height: opened ? 17 : 14, color: 'inherit' }} />}
        disabled={disabled}
      >
        <Typography variant="h4" semiBold>
          Filters
        </Typography>
      </Button>
    )
  }
)

const Filters = ({
  className,
  children,
  opened,
  total,
  contentLoading,
  disabled,
  reset,
  anchorEl,
  toggleOpen,
  shift
}: PropsWithChildren<FilterProps>) => {
  const { startOnboarding } = useOnboarding()
  const search = updateQuerystring(useLocation().search, 'page', '')
  const { top = 0, height = 0 } = anchorEl?.getBoundingClientRect() || {}
  const barShown = !!toggleOpen

  const { classes, cx } = useStyles({ shift, barShown })
  const anchorStartPos = top + height

  useEffect(() => {
    if (!contentLoading && !disabled && barShown) {
      startOnboarding('filterbar')
    }
  }, [contentLoading, disabled, barShown])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <>
      <Wide>
        <Box height={1} className={cx({ [classes.shift]: !!shift })}>
          {barShown && (
            <Bar className={classes.bar}>
              <Button variant="text" color="secondary" onClick={toggleOpen} disabled={disabled}>
                <FontAwesomeIcon
                  icon={['far', opened ? 'chevron-left' : 'chevron-right']}
                  style={{ fontSize: 12 }}
                  className={expandFilterOnboardingTarget}
                />
              </Button>
            </Bar>
          )}
          <Collapse
            in={opened}
            orientation="horizontal"
            collapsedSize={23.5}
            classes={{ root: classes.collapse, wrapperInner: classes.wrapperInner }}
          >
            <Box className={cx(classes.wrapper, className)}>
              <Fade in={opened}>
                <div>
                  {reset && (
                    <Box className={classes.topBlock}>
                      <Typography variant="h4" semiBold>
                        Filters
                      </Typography>
                      <Button disabled={disabled} variant="text" color="secondary" onClick={reset} disablePadding>
                        Reset
                      </Button>
                    </Box>
                  )}
                  {children}
                </div>
              </Fade>
            </Box>
          </Collapse>
        </Box>
      </Wide>

      <Narrow>
        {anchorEl && (
          <Popover
            open={opened}
            disableEnforceFocus
            hideBackdrop
            elevation={0}
            style={{ zIndex: 1101, top: anchorStartPos, maxHeight: `calc(100vh - ${anchorStartPos}px)` }}
            anchorEl={anchorEl}
            classes={{ paper: classes.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Box className={cx(classes.wrapper, className)}>
              <Box className={classes.topBlock}>
                <Box>
                  {reset && (
                    <Button disabled={disabled} variant="text" color="secondary" onClick={reset} disablePadding>
                      Reset
                    </Button>
                  )}
                  <Typography variant="h4" semiBold>
                    Filters
                  </Typography>
                </Box>
                <Box>
                  {contentLoading && <CircularProgress size={18} />}
                  {!contentLoading && typeof total === 'number' && (
                    <Typography>
                      {total} result{total !== 1 ? 's' : ''}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box className={classes.content}>{children}</Box>
            </Box>
          </Popover>
        )}
      </Narrow>
    </>
  )
}

Filters.Checkbox = Checkbox
Filters.Radio = Radio

export * from './data'
export default Filters
