import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountCircle } from '@mui/icons-material'
import { Box, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper, AvatarText } from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { addMissedProtocol } from '_core/helpers/string'

const useStyles = makeStyles()((theme) => ({
  spaceDown: {
    marginBottom: theme.spacing(0.5)
  },
  item: {
    textAlign: 'center',
    lineHeight: theme.spacing(2),
    letterSpacing: '0.25px'
  },
  jobTitle: {
    boxSizing: 'border-box',
    overflowWrap: 'anywhere',
    [theme.breakpoints.up('md')]: {
      marginTop: `-${theme.spacing(0.2)}`
    }
  },
  assignedTo: {
    padding: `0px ${theme.spacing(3.5)}`,
    boxSizing: 'border-box',
    lineHeight: '22px',
    textAlign: 'center'
  },
  link: {
    color: theme.palette.secondary.main
  },
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: 'calc(100vh - 170px)',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.background.darker
    },
    [theme.breakpoints.up('md')]: {
      marginTop: `-${theme.spacing(2)}`,
      marginRight: `-${theme.spacing(2)}`,
      borderRadius: theme.spacing(0.5),
      minHeight: '100%'
    },
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%'
    }
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    wordBreak: 'break-word'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.text.disabled,
    margin: `${theme.spacing(1.5)} 0px`
  },
  externalLink: {
    marginTop: `-${theme.spacing(1)}`,
    display: 'inline-flex',
    alignItems: 'center'
  },
  accountCircle: {
    width: 62.4,
    height: 62.4,
    margin: -5.2
  },
  iconButton: {
    fontSize: 14
  }
}))

const iconsMap: { [key: string]: IconName } = {
  phone: 'phone',
  email: 'envelope'
}

const ProfilePreview = ({ loading, owner, account, name, titles = [], emails = [], phones = [], websites = [] }: any) => {
  const { classes, cx } = useStyles()
  const renderIcon = (icon: IconName) => {
    return <FontAwesomeIcon className={classes.icon} icon={['fas', icon]} style={{ width: '10.5px' }} />
  }

  const formattedWebsiteUrl = (website: string) => (website ? addMissedProtocol(website.toLowerCase()) : '')

  return (
    <Box className={classes.wrapper}>
      {loading || (name && ((emails && emails[0]) || (websites && websites[0]))) ? (
        <AvatarWrapper hideName name={loading ? '' : name} userKey={emails[0]} logoUrl={websites[0]} size="md" mode="dark" />
      ) : (
        <AccountCircle color="secondary" className={classes.accountCircle} />
      )}
      {!loading && (
        <AvatarText
          name={name || 'Name not specified'}
          color={`text.${name ? 'primary' : 'disabled'}`}
          nameMode={name ? 'multiline' : 'inline'}
          size="md"
        />
      )}

      {(loading || !!titles.length) &&
        titles.map((title: string, i: number) => (
          <Skeleton condition={loading} width="80%" height={20} key={i}>
            <Box className={cx(classes.jobTitle, classes.spaceDown)}>
              <Tooltip title={title}>
                <Typography classes={{ root: classes.item }} noWrap color="text.hint">
                  {title}
                </Typography>
              </Tooltip>
            </Box>
          </Skeleton>
        ))}
      {(loading || account?.name) && (
        <Skeleton condition={loading} width="80%" height={20}>
          <Box className={classes.externalLink}>
            <Tooltip title={account?.name}>
              <Typography classes={{ root: classes.item }} noWrap color="text.hint">
                {account?.name}
              </Typography>
            </Tooltip>
            <IconButton<typeof Link> component={Link} to={{ pathname: account?.url }} target="_blank">
              <FontAwesomeIcon icon={['far', 'external-link']} className={classes.iconButton} />
            </IconButton>
          </Box>
        </Skeleton>
      )}
      {(loading || !!websites.length) &&
        websites.map((website: string, i: number) => (
          <Skeleton condition={loading} width="80%" height={20} key={i}>
            <Box className={classes.externalLink}>
              <Tooltip title={website || 'Website url not specified'}>
                <Typography classes={{ root: classes.item }} noWrap color={`text.${website ? 'hint' : 'disabled'}`}>
                  {website || 'Website url not specified'}
                </Typography>
              </Tooltip>
              <IconButton<typeof Link> component={Link} to={{ pathname: formattedWebsiteUrl(website) }} target="_blank" disabled={!website}>
                <FontAwesomeIcon icon={['far', 'external-link']} className={classes.iconButton} />
              </IconButton>
            </Box>
          </Skeleton>
        ))}
      {!loading && <Divider classes={{ root: classes.divider }} />}
      {loading || !!emails.length
        ? emails.map((email: string, i: number) => (
            <Skeleton condition={loading} width="80%" height={20} key={i}>
              <Box className={classes.spaceDown}>
                {renderIcon(iconsMap.email)}
                <Tooltip title={email || 'Email address not specified'}>
                  <Typography classes={{ root: classes.item }} noWrap color={`text.${email ? 'hint' : 'disabled'}`}>
                    {email || 'Email address not specified'}
                  </Typography>
                </Tooltip>
              </Box>
            </Skeleton>
          ))
        : null}

      {!!phones.length && (
        <Box flexDirection="column" alignItems="center">
          {phones.map((phone: string) => (
            <Box key={phone} className={classes.spaceDown}>
              {renderIcon(iconsMap.phone)}
              <Typography classes={{ root: classes.item }} noWrap color="text.hint">
                {phone}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {!loading && (!!emails.length || !!phones.length) && <Divider classes={{ root: classes.divider }} />}
      {owner?.name && (
        <>
          <Typography classes={{ root: cx(classes.item, classes.spaceDown) }} color="text.hint">
            {websites[0] ? 'Account' : 'Contact'} owner
          </Typography>
          <Box className={classes.externalLink}>
            <Tooltip title={owner?.name}>
              <Typography classes={{ root: classes.item }} noWrap color="text.hint">
                {owner?.name}
              </Typography>
            </Tooltip>
            {owner?.url && (
              <IconButton<typeof Link>
                component={Link}
                icon={['far', 'external-link']}
                classes={{ root: classes.iconButton }}
                to={{ pathname: owner?.url }}
                target="_blank"
              />
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default ProfilePreview
