import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin, faSalesforce, faGoogle, faWindows, faTwitter, faGithub, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {
  faBuilding,
  faAddressBook,
  faQuestionCircle,
  faAddressCard,
  faComments,
  faComment as farComment,
  faIdBadge,
  faHandshake,
  faEnvelope,
  faStar,
  faEdit,
  faUser,
  faCheckCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faCheckCircle as faCheckCircleSolid,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faShareAlt as faShareAltSolid
} from '@fortawesome/free-solid-svg-icons'
import { faFile, faExpandAlt, faArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-duotone-svg-icons'
import { faTools as faToolsLight, faTimesCircle, faCopy, faClockRotateLeft as faClockRotateLeftLight } from '@fortawesome/pro-light-svg-icons'
import {
  faBusinessTime,
  faDownload,
  faUserMinus,
  faBriefcase,
  faPhone,
  faPhoneOffice,
  faThList,
  faFingerprint,
  faTools,
  faCog,
  faTimes,
  faTachometerSlowest,
  faUsers,
  faFilter,
  faInboxIn,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarClock,
  faClipboardListCheck,
  faDatabase,
  faSuitcase as farSuitcase,
  faPaperPlane,
  faSignal,
  faSitemap,
  faBolt,
  faLink,
  faPlus,
  faCheck,
  faSearch,
  faInfoCircle,
  faList,
  faProjectDiagram,
  faTh,
  faEllipsisV,
  faObjectUngroup,
  faClock,
  faExternalLink,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faUnlink,
  faAlignRight,
  faExchange,
  faBan,
  faUndo,
  faMobileAlt,
  faCloudUploadAlt,
  faSortAmountDown,
  faSortAmountUp,
  faFileDownload,
  faGlobe,
  faQuestion,
  faWindowRestore,
  faLock as farLock,
  faLockOpen,
  faMerge,
  faListCheck,
  faArrowsRotate,
  faArrowLeft,
  faArrowRight,
  faSignOut,
  faExpand,
  faArrowDown,
  faArrowUp,
  faBarsFilter,
  faArrowRotateRight,
  faMessageExclamation,
  faUserPlus,
  faMailbox,
  faListTree,
  faTag,
  faTags,
  faTrash,
  faTrashSlash,
  faCirclePlus,
  faCircleMinus,
  faEye,
  faEyeSlash,
  faSliders,
  faChevronsDown,
  faChevronsRight,
  faComputerMouse,
  faRoute,
  faShieldCheck,
  faTrashCan,
  faBoxArchive,
  faBoxOpen,
  faClockRotateLeft,
  faSigma,
  faUserSlash,
  faMagnifyingGlassMinus,
  faArrowProgress,
  faLayerPlus,
  faChartColumn,
  faWavePulse,
  faRotate,
  faMoneyCheckPen,
  faSlidersH,
  faArrowUpWideShort,
  faArrowDownWideShort,
  faChartSimple
} from '@fortawesome/pro-regular-svg-icons'
import {
  faChartNetwork,
  faUsersClass,
  faFilter as faFilterStrong,
  faCommentAltLines,
  faMinusCircle,
  faUndo as faSolidUndo,
  faEquals,
  faEnvelope as faEnvelopSolid,
  faPhone as faPhoneSolid,
  faTasks,
  faTrash as faTrashSolid,
  faFax,
  faExclamationTriangle,
  faBell,
  faVideo,
  faPen,
  faCloudUploadAlt as faCloudUploadAltSolid,
  faTimes as faTimesSolid,
  faLocationDot,
  faHome,
  faCalendarXmark,
  faReply,
  faCloudDownloadAlt,
  faAddressCard as faAddressCardSolid,
  faArrowRightToBracket,
  faComment,
  faEarthAmericas,
  faLock,
  faLockOpen as faLockOpenSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faBars,
  faChartSimpleHorizontal,
  faMessageArrowDown,
  faMessageArrowUp,
  faMessage,
  faListTree as faListTreeSolid,
  faCloudArrowUp,
  faIndustry,
  faUser as faUserSolid,
  faUserTie,
  faChartColumn as faChartColumnSolid,
  faDiagramVenn,
  faUsers as faUsersSolid,
  faBuilding as faBuildingSolid,
  faHashtag,
  faBriefcase as faBriefcaseSolid,
  faHandshake as faHandshakeSolid,
  faGraduationCap,
  faTag as faTagSolid,
  faMessages,
  faUserPlus as faUserPlusSolid,
  faDollarSign,
  faFile as faFileSolid,
  faClock as faClockSolid,
  faThumbsUp as faThumbsUpSolid,
  faSearch as faSearchSolid,
  faShareNodes,
  faPlay
} from '@fortawesome/pro-solid-svg-icons'
import {
  faCalendarClock as faCalendarClockThin,
  faPartyHorn,
  faThumbsUp as faThumbsUpThin,
  faEnvelopeOpenText,
  faSuitcase as faSuitcaseThin,
  faStar as faStarThin,
  faHandshake as faHandshakeThin,
  faUserGroup as faUserGroupThin,
  faBuildings as faBuildingsThin,
  faAddressBook as faAddressBookThin,
  faChartColumn as faChartColumnThin,
  faBriefcase as faBriefcaseThin,
  faUsers as faUsersThin,
  faSigma as faSigmaThin,
  faUsersClass as faUsersClassThin
} from '@fortawesome/pro-thin-svg-icons'

export default library.add(
  faBusinessTime,
  faBuilding,
  faBuildingSolid,
  faAddressBook,
  faQuestionCircle,
  faQuestion,
  faFingerprint,
  faAddressCard,
  faBriefcase,
  faBriefcaseSolid,
  faBriefcaseThin,
  faComments,
  faChartNetwork,
  faChartSimple,
  faSignOut,
  faUsersClass,
  faUsersClassThin,
  faDownload,
  faUserMinus,
  faUserPlus,
  faIdBadge,
  faHandshake,
  faHandshakeSolid,
  faHandshakeThin,
  faTools,
  faToolsLight,
  faCog,
  faLinkedin,
  faSalesforce,
  faPhone,
  faPhoneSolid,
  faEnvelope,
  faThList,
  faTimes,
  faTimesSolid,
  faTachometerSlowest,
  faUsers,
  faUsersThin,
  farSuitcase,
  faFilter,
  faFilterStrong,
  faCheckCircle,
  faCheckCircleSolid,
  faInboxIn,
  faPaperPlane,
  faSignal,
  faBolt,
  faSitemap,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarXmark,
  faCalendarPlus,
  faCalendarClock,
  faCalendarClockThin,
  faClipboardListCheck,
  faPlus,
  faDatabase,
  faFile as any,
  faCheck,
  faLink,
  faStar,
  faCommentAltLines,
  faSearch,
  faSearchSolid,
  faUser,
  faArrowLeft,
  faArrowRight,
  faArrowLeftSolid,
  faArrowRightSolid,
  faInfoCircle,
  faList,
  faProjectDiagram,
  faTh,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faMinusCircle,
  faEquals,
  faEllipsisV,
  faUndo,
  faObjectUngroup,
  faTasks,
  faAlignRight,
  faTrash,
  faTrashSolid,
  faClock,
  faClockSolid,
  faUnlink,
  faExternalLink,
  faFax,
  faUnlink,
  faExpandAlt,
  faAlignRight,
  faExchange,
  faUndo,
  faSolidUndo,
  faBan,
  faUndo,
  faEdit,
  faMobileAlt,
  faPhoneOffice,
  faFax,
  faEnvelopSolid,
  faExclamationTriangle,
  faCloudUploadAlt,
  faCloudUploadAltSolid,
  faBell,
  faSortAmountDown,
  faSortAmountUp,
  faHome,
  faFileDownload,
  faTimesCircle,
  faGlobe,
  faWindowRestore,
  faCloudDownloadAlt,
  faCopy,
  faTimesSolid,
  faArrowRightToBracket,
  farComment,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faVideo,
  faPen,
  faLocationDot,
  faAddressCardSolid,
  faReply,
  faComment,
  faGoogle,
  faWindows,
  faEarthAmericas,
  faPartyHorn,
  faThumbsUpSolid,
  faThumbsUpThin,
  faEnvelopeOpenText,
  faLock,
  farLock,
  faLockOpen,
  faLockOpenSolid,
  faQuestionCircleSolid,
  faMerge,
  faListCheck,
  faArrowsRotate,
  faExpand,
  faArrowDown,
  faArrowUp,
  faBarsFilter,
  faBars,
  faChartSimpleHorizontal,
  faUserGroupThin,
  faBuildingsThin,
  faSuitcaseThin,
  faStarThin,
  faAddressBookThin,
  faMessage,
  faMessageArrowDown,
  faMessageArrowUp,
  faCloudArrowUp,
  faTwitter,
  faGithub,
  faFacebook,
  faIndustry,
  faUserTie,
  faChartColumnSolid,
  faChartColumnThin,
  faChartColumn,
  faDiagramVenn,
  faUsersSolid,
  faHashtag,
  faGraduationCap,
  faArrowRotateRight,
  faMessageExclamation,
  faMailbox,
  faListTreeSolid,
  faListTree,
  faTag,
  faTagSolid,
  faTags,
  faCirclePlus,
  faCircleMinus,
  faEye,
  faEyeSlash,
  faMessages,
  faSliders,
  faChevronsDown,
  faChevronsRight,
  faComputerMouse,
  faRoute,
  faShieldCheck,
  faTrashCan,
  faBoxArchive,
  faClockRotateLeft,
  faClockRotateLeftLight,
  faUserPlusSolid,
  faSigma,
  faSigmaThin,
  faDollarSign,
  faFileSolid,
  faUserSolid,
  faUserSlash,
  faMagnifyingGlassMinus,
  faBoxOpen,
  faTrashSlash,
  faShareNodes,
  faPlay,
  faArrowProgress,
  faLayerPlus,
  faWavePulse,
  faRotate,
  faSlidersH,
  faMoneyCheckPen,
  faArrowUpWideShort,
  faArrowDownWideShort,
  faShareAltSolid
)
