import { useState } from 'react'

const useMDIntergartionUserSettings = () => {
  const [state, setState] = useState<MarketDataIntegration>()

  const setInitial = (data: MarketDataIntegration) => {
    setState(data)
  }

  return { setInitial, ...state }
}

export default useMDIntergartionUserSettings
