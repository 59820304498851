import { useEffect, useState, useCallback, useContext, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { useWide } from '_core/components/layout'
import { sortMap } from '_core/components/sort'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import { getUTC } from 'utils/Utils'

type InitialParams = IntroducersPageParams & { showInteractionsFilters: boolean; isOpened: boolean }

const introducersSaveData = {
  endpoint: '/usersettings/introducersfilter',
  getData: (params: InitialParams): IntroducersInit => {
    const { sort, rowsPerPage, where, interaction, period, days, and, showInteractionsFilters, viewMode, includeTags, excludeTags, isOpened } = params

    return {
      sort: sort || 'ScoreDesc',
      rowsPerPage: +(rowsPerPage || '20') as RowPerPageOptionsType,
      touchpointDateType: where || 'First',
      touchpointType: interaction || 'Any',
      dateConditionType: period || 'Anytime',
      dayCount: +(days || '7') as DaysOptions,
      futureMeetingNotScheduled: !!and,
      showInteractionsFilters,
      expandedView: viewMode === 'expanded',
      includeTags: includeTags ? JSON.parse(includeTags) : includeTags,
      excludeTags: excludeTags ? JSON.parse(excludeTags) : excludeTags,
      isOpened
    }
  }
}

const useIntroducersUserSettings = (showStatsColumns?: boolean) => {
  const wide = useWide()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<IntroducersPageParams>()

  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<IntroducersInit>()

  const { teamContextValue } = useContext(TeamContext)
  const { dataVisibility } = useUserDataVisibility()

  const [isOpened, setOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(!search)
  const [params, setParams] = useState<InitialParams>()

  const allowSetInit = useRef<boolean>(false)

  const touchpointData = (({ days, period, and, interaction, where }) => ({ days, period, and, interaction, where }))(params || queryParams)

  const { result: teamResult } = useEntityEndpoint<{ results: TeamDataRes }>(`/teams/${teamContextValue.teamNumber}`)

  const { allowFilteringByInteractions } = dataVisibility || {}
  const { shareInteractionStats: isInteractionsEnabled } = teamResult?.results?.defaultSharingOptions || {}

  const { where } = queryParams

  const interactionsActive = !!where
  const interactionsFiltersShown =
    typeof allowFilteringByInteractions === 'boolean' && typeof isInteractionsEnabled === 'boolean'
      ? !!(allowFilteringByInteractions && isInteractionsEnabled)
      : null

  const interactionsColumnsShown = typeof isInteractionsEnabled === 'boolean' && isInteractionsEnabled

  const getInitialParams = useCallback((data: IntroducersInit): InitialParams => {
    const {
      sort,
      rowsPerPage,
      showInteractionsFilters,
      touchpointDateType,
      touchpointType,
      dateConditionType,
      dayCount,
      futureMeetingNotScheduled,
      expandedView,
      includeTags,
      excludeTags,
      isOpened
    } = data
    const days: NumberToString<DaysOptions> = `${dayCount}`

    return {
      sort,
      showInteractionsFilters,
      rowsPerPage: `${rowsPerPage || 20}`,
      where: touchpointDateType,
      viewMode: expandedView ? 'expanded' : 'collapsed',
      includeTags: includeTags?.length ? JSON.stringify(includeTags) : '',
      excludeTags: excludeTags?.length ? JSON.stringify(excludeTags) : '',
      isOpened,
      ...(dayCount ? { days } : {}),
      ...(futureMeetingNotScheduled ? { and: 'noFutureScheduled' } : {}),
      ...(touchpointType !== 'Any' ? { interaction: touchpointType } : {}),
      ...(dateConditionType !== 'Anytime' ? { period: dateConditionType } : {})
    }
  }, [])

  const setInitial = useCallback(
    (data: IntroducersInit) => {
      if (wide) {
        setOpened(data.isOpened)
      }
      setParams(getInitialParams(data))
      setLoading(false)

      allowSetInit.current = true
    },
    [getInitialParams, setLoading, wide]
  )

  const handleChange = (updates: typeof queryParams, additionalParams?: { [key in 'intActive' | 'isOpened']?: boolean } | undefined) => {
    const { intActive = interactionsActive, isOpened = params?.isOpened } = additionalParams || {}

    const hiddenParamsToSave = [
      { params: { showInteractionsFilters: intActive, isOpened }, condition: true },
      { params: touchpointData, condition: !intActive }
    ]
      .filter(({ condition }) => condition)
      .reduce((acc, { params }) => ({ ...acc, ...params }), {})

    return save<void>(getIntroducersSaveData(hiddenParamsToSave), { ...queryParams, ...updates })
  }

  useEffect(() => {
    const updateCallback = (prevState: typeof params) => {
      if (prevState) {
        const { days, period, and, interaction, where } = queryParams
        const updateParams = [{ params: { days, period, and, interaction, where }, condition: interactionsActive }]
          .filter(({ condition }) => condition)
          .reduce((acc, { params }) => ({ ...acc, ...params }), {})

        return { ...prevState, ...updateParams }
      }
    }

    setParams(updateCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const getExcludeEmpty = (sort: ScoreType | StatSortType | undefined): 'true' | 'false' | null => {
    if (!sort) {
      return null
    }

    const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)
    return sortByField && sortMap[sortByField].excludeEmpty ? `${sortMap[sortByField].excludeEmpty}` : null
  }

  useEffect(() => {
    if (allowSetInit.current && interactionsFiltersShown !== null && params) {
      const baseParams = (({ sort, rowsPerPage, viewMode, includeTags, excludeTags }) => ({
        sort,
        rowsPerPage,
        viewMode,
        includeTags,
        excludeTags
      }))(params)

      const tData = (({ and, period, where, interaction, days }) => ({ and, period, where, interaction, days }))(params)
      const { days, ...restTData } = tData

      updateQuery({
        ...baseParams,
        // excludeEmpty: getExcludeEmpty(baseParams.sort),
        ...(params.showInteractionsFilters && interactionsFiltersShown ? { ...restTData, ...(restTData.period ? { days } : {}) } : {}),
        ...(showStatsColumns && !queryParams.from && !queryParams.to
          ? { from: getUTC().startOf('year').toISOString(), to: getUTC().endOf('year').toISOString() }
          : {})
      })

      allowSetInit.current = false
    }
  }, [updateQuery, interactionsFiltersShown, params, queryParams.from, queryParams.to])

  const getIntroducersSaveData = (additionalParams: any) => ({
    ...introducersSaveData,
    getData: (params: typeof queryParams) => introducersSaveData.getData({ ...params, ...additionalParams })
  })

  const getEmptyRelatedParams = (keys: string[]) => {
    return keys.reduce((acc, item) => ({ ...acc, [item]: undefined }), {} as { [key: string]: undefined })
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/introducersfilter' })
    if (defaultData) {
      const params = getInitialParams(defaultData)
      setParams(params)
      const { showInteractionsFilters, isOpened } = params
      const baseParams = (({ sort, rowsPerPage, viewMode, includeTags, excludeTags }) => ({
        sort,
        rowsPerPage,
        viewMode,
        includeTags,
        excludeTags
      }))(params)

      const tData = (({ and, period, where, interaction, days }) => ({ and, period, where, interaction, days }))(params)
      const { days, ...restTData } = tData

      const saveData = {
        ...introducersSaveData,
        getData: (params: typeof queryParams) =>
          introducersSaveData.getData({ ...params, showInteractionsFilters, isOpened, ...(!showInteractionsFilters ? tData : {}) })
      }

      const interactions =
        showInteractionsFilters && interactionsFiltersShown
          ? { ...restTData, ...(restTData.period ? { days } : {}) }
          : getEmptyRelatedParams(Object.keys(touchpointData || {}))

      const newQueryParams = {
        ...baseParams,
        ...interactions
      }

      await save(saveData, newQueryParams)
      setOpened(isOpened)
      setLoading(false)
      return newQueryParams
    }
  }

  const toggleInteractionSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target

    const paramsToClear = getEmptyRelatedParams(Object.keys(touchpointData || {}))
    const { days, ...restTouchPointData } = touchpointData

    const optionalParams = [{ days: touchpointData.days, condition: touchpointData.period }]
      .filter(({ condition }) => condition)
      .reduce((acc, { condition, ...param }) => ({ ...acc, ...param }), {})

    return handleChange({ ...(checked ? { ...restTouchPointData, ...optionalParams } : paramsToClear) }, { intActive: checked })
  }

  const toggleOpen = () => {
    const newState = !isOpened
    setOpened(newState)

    if (wide) {
      setParams((prevState) => (prevState ? { ...prevState, isOpened: newState } : prevState))
      return handleChange({}, { isOpened: newState })
    }
  }

  return {
    params,
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleInteractionSwitch,
    loading,
    opened: isOpened,
    toggleOpen
  }
}

export default useIntroducersUserSettings
