import parsePhoneNumber from 'libphonenumber-js'
import moment, { Moment as MomentType, MomentInput, MomentFormatSpecification } from 'moment'

type PhoneNumberType = {
  StandardizedPhoneNumber: string
  PhoneType?: string
  TypeShortcut?: string
  currentAsOf?: Date
}

export type WorkExperienceType = {
  companyIdentity: string
  companyName: string
  jobTitle?: string
  currentAsOf?: string
}

export const dateFormatURLQuery = 'MM-DD-YYYY'
export const dateFormat = 'MM/DD/YYYY'
const timeFormat = 'h:mm A'

export const dateToStringWithTodayComparing = (date?: Date | null) => {
  if (date) {
    if (new Date(date) > new Date()) {
      return moment(new Date()).format(dateFormat)
    } else {
      return moment(new Date(date)).format(dateFormat)
    }
  } else {
    return ''
  }
}

export const workExperienceToString = (affiliation?: WorkExperienceType | null) => {
  var result = ''

  if (affiliation) {
    if (affiliation.jobTitle) {
      result = affiliation.jobTitle + ' at '
    }

    result += affiliation.companyName
  }

  return result
}

export const phoneToString = (phone?: PhoneNumberType | null) => {
  if (phone?.StandardizedPhoneNumber) {
    const formattedDigits = parsePhoneNumber(phone.StandardizedPhoneNumber)?.formatInternational()

    if (phone.PhoneType) {
      return phone.PhoneType[0].toUpperCase() + ' ' + formattedDigits
    } else {
      return formattedDigits
    }
  } else {
    return ''
  }
}

export const triggerDownloadXl = (data: any, dataType: any, onclickHandler?: any) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  let url = window.URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = url
  a.download = `${dataType}.xlsx`
  a.onclick = onclickHandler || null
  a.click()
}

export const triggerDownload = (data: any, dataType: any) => {
  const blob = new Blob([data], { type: 'text/csv' })
  let url = window.URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = url
  a.download = `${dataType}.csv`
  a.click()
}

export const convertObjectToCsv = (columnName: string, objectToConvert: any) => {
  let result = ''

  if (columnName === 'phoneNumbers' && Array.isArray(objectToConvert)) {
    result += objectToConvert
      .map((item) => {
        return phoneToString(item)
      })
      .join(' | ')
  } else if (columnName === 'workExperiences' && Array.isArray(objectToConvert)) {
    result += objectToConvert
      .map((item) => {
        return workExperienceToString(item)
      })
      .join(' | ')
  } else if (columnName === 'phoneNumber') {
    result += phoneToString(objectToConvert)
  } else if (Array.isArray(objectToConvert)) {
    result += objectToConvert
      .map((item) => {
        if (typeof item !== 'object') {
          return item
        }

        return convertObjectToCsv(columnName, item)
      })
      .join(' | ')
  } else {
    for (const key in objectToConvert) {
      if (typeof objectToConvert[key] !== 'object' && key !== 'more' && objectToConvert[key]) {
        result += `${key}: ${objectToConvert[key]} `
      } else {
        result += convertObjectToCsv(columnName, objectToConvert[key])
      }
    }
  }

  return result
}

function filterHeaders(value: string) {
  return !value.endsWith('Id') && !(value === 'isUser') && !(value === 'isTeamMember') && !(value === 'keyContact')
}

export const convertToCsv = (data: any) => {
  const csvRows = []

  // Get columns
  const headers = Object.keys(data[0]).filter(filterHeaders)

  csvRows.push(headers.join(','))

  // Loop over values
  for (const row of data) {
    const values = headers.map((header) => {
      if (row[header] != null) {
        var columnData = ''

        if (typeof row[header] === 'object') {
          columnData = convertObjectToCsv(header, row[header])
        } else {
          columnData = row[header]
        }

        var escaped = ('' + columnData).replace(/"/g, '\\"')
        return `"${escaped}"`
      } else {
        return ''
      }
    })

    csvRows.push(values.join(','))
  }

  // Join and return all lines
  return csvRows.join('\n')
}

export function getUTC(date?: MomentInput, format?: MomentFormatSpecification): MomentType {
  return moment.utc(date, format)
}

export function getLocal(date?: MomentInput, format?: MomentFormatSpecification): MomentType {
  return moment(date, format)
}

export function formatDate(date: MomentInput, format = dateFormat): string {
  return getLocal(date).format(format)
}

export function formatDateTime(date: MomentInput, format = `${dateFormat} ${timeFormat}`): string {
  return getLocal(date).format(format)
}

export function formatTime(date: MomentInput, format = timeFormat): string {
  return getLocal(date).format(format)
}

export function getDaysAgo(date: MomentInput): string {
  return moment(date).fromNow()
}

export function formatDateTimeFromNow(date: MomentInput, max: number, format = `${dateFormat} ${timeFormat}`): string {
  const diff = moment().diff(date, 'days')
  return diff <= max ? getDaysAgo(date) : formatDateTime(date, format)
}

export function formatDateFromNow(date: MomentInput, max: number, format = dateFormat): string {
  const titles = ['today', 'yesterday', 'tomorrow']
  const diff = moment().diff(date, 'days')

  const result = moment(date).calendar(null, {
    lastDay: '[yesterday]',
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    sameElse: () => '[' + date + ']'
  })
  return titles.includes(result) ? result : Math.abs(diff) <= max ? getDaysAgo(date) : formatDate(date, format)
}

export function isSameOrBetween(checkedDate: MomentInput, from: MomentInput, to: MomentInput) {
  return Boolean(getLocal(checkedDate).isBetween(getLocal(from), getLocal(to)) || getLocal(checkedDate).isSame(getLocal(from)))
}

export const formatMonetaryValue = (value: number) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else {
    return value.toFixed(0)
  }
}

export const ellipsisText = (text: string, maxLength: number) => (text ? (text.length > maxLength ? text.substring(0, maxLength) + '...' : text) : '')
