import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { TeamContext } from '_core/context/TeamContext'

import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'

import { post } from 'utils/httpUtils'
import { getLocal, getUTC } from 'utils/Utils'

import Paths from 'Paths'

const useCompanyActivities = (isReady: boolean, companyId: string, contributorId?: string) => {
  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { UserKeyMd5, UserKey } = userKeyResult?.results || {}
  const { teamContextValue } = useContext(TeamContext)

  const userCompany = UserKey?.split('@')[1]

  const { queryParams } = useSearchQuery<EntityActivitiesPageParams>()
  const { interaction, from, to, sort = 'OldestToNewest' } = queryParams

  const [{ emails, loading: emailsLoading }, setEmailsData] = useState<{
    emails?: ActivityEmailsRespType[]
    loading: boolean
  }>({ loading: false })
  const [{ meetings, loading: meetingsLoading }, setMeetingsData] = useState<{
    meetings?: ActivityMeetingsRespType[]
    loading: boolean
  }>({ loading: false })

  const [reqIndex, setReqIndex] = useState(0)
  const { months } = useActivityStatsPeriod({ fromUTC: getUTC(decodeURIComponent(from)), toUTC: getUTC(decodeURIComponent(to)), sort })

  const more = useCallback(() => {
    setReqIndex((prevReqIndex) => {
      if (prevReqIndex < months.length - 1) {
        return prevReqIndex + 1
      } else {
        setEmailsData(({ emails = [] }) => ({ emails, loading: false }))
        return prevReqIndex
      }
    })
  }, [months])

  const reload = () => {
    setMeetingsData({ loading: false })
    setEmailsData({ loading: false })
    setReqIndex(0)
  }

  const payload = useMemo(
    () => ({
      direction: sort,
      skip: 0,
      take: 10000,
      participantTake: 10000,
      withMeetingTags: false,
      havingMeetingTags: '',
      lackingMeetingTags: ''
    }),
    [sort]
  )

  const interactionPayloads = useCompanyActivitiesPayloads(companyId, contributorId)

  useEffect(() => {
    // think about: request chunks in scope on month
    const fetchEmails = async (plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } }) => {
      const { payload: interactionPld } = [
        {
          payload: plds.meetings,
          condition: !interaction
        },
        {
          payload: plds.outbound,
          condition: interaction === 'Outbound'
        },
        {
          payload: plds.inbound,
          condition: interaction === 'Inbound'
        }
      ].find(({ condition }) => condition) || { payload: {} }

      setEmailsData((prevState) => ({ ...prevState, loading: true }))
      const dateRangePayload = months[reqIndex]

      const emailsData = await post<{
        data: ActivityEmailsRespType[]
        total_item_count: number
      }>(`/emails/bymonth?teamNumber=${teamContextValue.teamNumber}`, {
        ...dateRangePayload,
        ...payload,
        ...interactionPld,
        localHoursShift: -(getLocal().utcOffset() / 60)
      })

      if (emailsData) {
        if (!emailsData.total_item_count) {
          more()
        } else {
          setEmailsData(({ emails = [] }) => ({
            loading: false,
            emails: [...emails, ...emailsData.data]
          }))
        }
      }
    }

    if (isReady && months.length && interactionPayloads && (!interaction || interaction !== 'Meeting')) {
      fetchEmails(interactionPayloads)
    }
  }, [months, interaction, isReady, more, payload, reqIndex, teamContextValue.teamNumber, companyId, interactionPayloads])

  useEffect(() => {
    const fetchMeetings = async (plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } }) => {
      setMeetingsData((prevState) => ({ ...prevState, loading: true }))

      const meetingsData = await post<{
        data: ActivityMeetingsRespType[]
        total_item_count: number
      }>(`/meetings/byperiod?teamNumber=${teamContextValue.teamNumber}`, {
        minDate: from,
        maxDate: to,
        ...payload,
        ...(!interaction || interaction === 'Meeting' ? plds.meetings : {})
      })

      if (meetingsData) {
        setMeetingsData(({ meetings = [] }) => ({
          loading: false,
          meetings: [...meetings, ...meetingsData.data]
        }))
      }
    }

    if (isReady && months.length && interactionPayloads && (!interaction || interaction === 'Meeting')) {
      fetchMeetings(interactionPayloads)
    }
  }, [from, to, months, interaction, isReady, payload, teamContextValue.teamNumber, companyId, interactionPayloads])

  const participantsMapper = ({
    StandardizedName: name,
    PersonIdText: email,
    PersonIdMd5: id,
    CompanyIdText: md5
  }: ActivityEmailsRespType['Participants']['data'][number]) => ({
    id,
    name: name || email || id, // Ask some participants name and email are missed, should I use md5 instead,
    email: email || '', // Ask some participants emails are missed,
    companyMd5: md5
  })

  const mtgs = meetings?.map(({ StartDateTime, Participants, CalGlobalKey }) => {
    const isFutureEvent = getLocal(StartDateTime).isAfter(getLocal())

    const companies = Participants.data.reduce((acc, { PersonIdText: email }) => {
      const companyDomain = email?.split('@')[1]
      return companyDomain && !acc.includes(companyDomain) ? [...acc, companyDomain] : acc
    }, [] as string[])

    const isParticipant = Participants.data.findIndex(({ PersonIdMd5 }) => PersonIdMd5 === UserKeyMd5) > -1

    const { pathname, params }: { pathname: string; params: Modify<ActivityEventPageParams, { participants?: string[]; companies?: string[] }> } =
      isParticipant
        ? { pathname: `${Paths._events}/${CalGlobalKey}`, params: { idType: 'CalGlobalKey' } }
        : {
            pathname: `${Paths._activities}/event`,
            params: {
              participants: Participants.data.map((p) => p.PersonIdText || p.PersonIdMd5),
              companies: companies,
              startTime: StartDateTime
            }
          }

    const from = Participants.data.filter(({ Role }) => Role === 'From').map(participantsMapper)
    const to = Participants.data.filter(({ Role }) => Role !== 'From').map(participantsMapper)

    return {
      from,
      to,
      companies: companies,
      dateTime: StartDateTime,
      actionText: isFutureEvent ? 'will meet' : 'met',
      icon: ['far', 'calendar-alt'] as IconProp,
      isInternal: companies.length === 1,
      detailsLink: stringifyUrl(pathname, params) // Check whether we can fetch by id,
    }
  })

  const emls = emails?.map(({ ExactSubmitTime, Participants }) => {
    const companies = Participants.data.reduce((acc, { PersonIdText: email }) => {
      const companyDomain = email?.split('@')[1]
      return companyDomain && !acc.includes(companyDomain) ? [...acc, companyDomain] : acc
    }, [] as string[])

    const from = Participants.data.filter(({ Role }) => Role === 'From').map(participantsMapper)
    const to = Participants.data.filter(({ Role }) => Role !== 'From').map(participantsMapper)

    const isOutbound = from.find(({ companyMd5 }) => companyMd5 === userCompany)

    const { pathname, params }: { pathname: string; params?: Modify<ActivityMessagePageParams, { participants?: string[]; companies?: string[] }> } =
      {
        pathname: `${Paths._activities}/message`,
        params: {
          participants: Participants.data.map((p) => p.PersonIdText || p.PersonIdMd5),
          companies: companies,
          startTime: ExactSubmitTime
        }
      }

    const { f, t, icon, text } =
      [
        {
          f: to,
          t: from,
          icon: ['fas', 'message-arrow-down'] as IconProp,
          text: 'received message from',
          condition: !isOutbound
        },
        {
          f: from,
          t: to,
          icon: ['fas', 'message-arrow-up'] as IconProp,
          text: 'sent message to',
          condition: isOutbound
        }
      ].find(({ condition }) => condition) || {}

    return {
      from: f,
      to: t,
      companies: companies,
      dateTime: ExactSubmitTime,
      actionText: text,
      icon,
      isInternal: companies.length === 1,
      detailsLink: stringifyUrl(pathname, params) // Check whether we can fetch by id,
    }
  })

  const mergeEmailsAndMeetings = (emails: NonNullable<typeof emls>, meetings: NonNullable<typeof mtgs>) => {
    const { year, month, maxDay, minDay } = months[reqIndex]
    const date = new Date(Date.UTC(year, month - 1, sort === 'OldestToNewest' ? maxDay : minDay)).setUTCHours(0, 0, 0, 0)

    const filteredMeetings = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.dateTime).setUTCHours(0, 0, 0, 0)
      return sort === 'OldestToNewest' ? meetingDate <= date : meetingDate >= date
    })

    return [...emails, ...filteredMeetings]
  }

  const sortActivities = (a: { [key: string]: any }, b: { [key: string]: any }) =>
    sort === 'OldestToNewest' ? getLocal(a.dateTime).diff(getLocal(b.dateTime)) : getLocal(b.dateTime).diff(getLocal(a.dateTime))

  const activities = (() => {
    if (!interaction && emls && mtgs) {
      return mergeEmailsAndMeetings(emls, mtgs).sort(sortActivities)
    }
    if (interaction === 'Meeting' && mtgs) {
      return [...mtgs].sort(sortActivities)
    }
    if (interaction && interaction !== 'Meeting' && emls) {
      return [...emls].sort(sortActivities)
    }
    return undefined
  })()

  return {
    loading: emailsLoading || meetingsLoading,
    activities,
    hasMore: interaction === 'Meeting' ? false : reqIndex < months.length - 1,
    more,
    reload
  }
}

export default useCompanyActivities
