import { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import PersonActivitiesList, { Heading } from '_core/components/ContactActivitiesList'
import Empty from '_core/components/Empty'
import Filters from '_core/components/filters/PersonActivities'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { Narrow } from '_core/components/layout'

import useCompanyActivities from '_core/hooks/useCompanyActivities'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useUserActivitiesUserSettings from '_core/hooks/useUserActivitiesUserSettings'

import UserSettings from '_core/UserSettings'

import { formatDate, getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const CompanyRelationshipActivitiesPage = () => {
  const { from: contributorId, to: company2Id } = useParams<{ from: string; to: string }>()
  const { queryParams } = useSearchQuery<EntityActivitiesPageParams>()
  const { from, to, sort } = queryParams
  const { setSubHeader } = useContext(LayoutContext)

  const {
    setInitial,
    reset,
    handleChange,
    loading: userSettingsLoading,
    toggleOpen,
    opened,
    isReady: isUserSettingsReady
  } = useUserActivitiesUserSettings()
  const { loading, activities, hasMore, more, reload } = useCompanyActivities(isUserSettingsReady, company2Id, contributorId)

  useEffect(() => {
    const fromUTC = getUTC(from)
    const toUTC = getUTC(to)

    setSubHeader(toUTC.diff(fromUTC, 'day') === 0 ? formatDate(fromUTC) : `${formatDate(fromUTC)} - ${formatDate(toUTC)}`)
  }, [setSubHeader, from, to])

  const onSettingsChange = (updates: Partial<EntityActivitiesPageParams>, additionalParams?: { [key in 'isOpened']?: boolean }) => {
    if ('interaction' in updates || 'sort' in updates) {
      reload()
    }
    handleChange(updates, additionalParams)
  }

  const contentLoading = !isUserSettingsReady || !activities || loading

  const filtersProps = {
    disabled: userSettingsLoading,
    opened,
    contentLoading,
    queryData: queryParams,
    handleChange: onSettingsChange,
    toggleOpen,
    reset
  }

  const toggleSort = () => {
    onSettingsChange({ sort: sort === 'NewestToOldest' ? 'OldestToNewest' : 'NewestToOldest' })
  }

  return (
    <Page>
      <Narrow>
        <UserSettings endpoint="/usersettings/useractivitiesfilter" setInitial={setInitial}>
          <Heading
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, toggle: toggleSort }}
            filters={<Filters {...filtersProps} />}
          />
          {!contentLoading && !activities.length && <Empty title="No activities found" />}
          {(contentLoading || activities.length > 0) && (
            <InfiniteScroll
              loading={contentLoading}
              dataLength={activities?.length || 0}
              next={more}
              refreshFunction={reload}
              hasMore={hasMore}
              scrollableTarget="activities_list"
            >
              <PersonActivitiesList activities={activities} />
            </InfiniteScroll>
          )}
        </UserSettings>
      </Narrow>
    </Page>
  )
}

export default CompanyRelationshipActivitiesPage
