import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'

import { AssociationListItem } from '_core/components/audit/AuditTuples'
import AudiTupleInvalidDialog from '_core/components/dialogs/AuditTupleInvalid'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import SearchInput from '_core/components/SearchInput'
import Widget from '_core/components/Widget'

import { checkOutlook } from '_core/helpers/outlook'

export type TuplesProps = {
  loading: boolean
  items: {
    lowIdentifier: {
      text: string
      md5: string
    }
    type: 'Association'
    highIdentifier: {
      text: string
      md5: string
    }
  }[]
  sourceBaseLink: string
  checkedTuples: string[]
  setCheckedTuples: Dispatch<SetStateAction<string[]>>
}

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 15
  },
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: `calc(100% - 39px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 39,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  }
}))

export const Heading = ({ openDialog, isDialogDisabled }: { openDialog: () => void; isDialogDisabled: boolean }) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { classes } = useStyles()
  const hasSidebar = isSidepanel() || checkOutlook()

  return (
    <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
      <Box display="flex">
        <SearchInput placeholder="Search tuples" variant="collapsed" wrapperClassName={classes.input} opened />
        <Box className={classes.icons}>
          <AudiTupleInvalidDialog.TriggerEl classes={{ root: classes.icon }} disabled={isDialogDisabled} open={openDialog} />
        </Box>
      </Box>
    </Widget>
  )
}

const TuplesList = (props: TuplesProps) => {
  const { classes } = useStyles()

  const toggleCheck = (e: ChangeEvent<HTMLInputElement>, uid: string) => {
    if (e.target.checked) {
      props.setCheckedTuples((prev) => [...prev, uid])
    } else {
      props.setCheckedTuples((prev) => prev.filter((id) => id !== uid))
    }
  }

  const items = props.items.map(({ lowIdentifier, highIdentifier }) => {
    const id1 = lowIdentifier.text || lowIdentifier.md5 || ''
    const id2 = highIdentifier.text || highIdentifier.md5 || ''
    const uid = `${id1} = ${id2}`

    return {
      id1,
      id2,
      checked: props.checkedTuples.includes(uid),
      toggleCheck: (e: ChangeEvent<HTMLInputElement>) => toggleCheck(e, uid),
      actions:
        id1 && id2 ? (
          <IconButton<typeof Link>
            component={Link}
            to={{ pathname: `${props.sourceBaseLink}/${encodeURIComponent(id1)}/${encodeURIComponent(id2)}` }}
            classes={{ root: classes.icon }}
            disablePT
            icon={['fas', 'list-tree']}
            hint="Sources"
          />
        ) : null
    }
  })

  return (
    <Widget scope="list">
      <Repeater direction="vertical" variant="list" component={AssociationListItem} skeleton={{ size: 5, loading: props.loading }} items={items} />
    </Widget>
  )
}

export default TuplesList
