import React, { useContext, useEffect, useState, useMemo, ComponentProps } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { IconButton } from '_shared/buttons'

import { AffiliationPopover } from '_core/components/Affiliation'
import { ActivityStatsPeriodMsg } from '_core/components/charts/ActivityStats'
import Download from '_core/components/Download'
import DownloadBulkControl from '_core/components/DownloadBulkControl'
import ExpandableDetails from '_core/components/ExpandableDetails'
import Grid, { DataGrid, GridTypes } from '_core/components/grid'
import {
  companyColumn,
  inboundCountColumn,
  jobTitleColumn,
  meetingsCountColumn,
  nameColumn,
  nameWithByLinesColumn,
  outboundCountColumn,
  scoreColumn
} from '_core/components/grid/columns'
import { Middle, Narrow, NarrowStrict, useWide, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'
import StatsContent from '_core/components/StatsContent'
import Widget from '_core/components/Widget'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats, { ActivityStatsMonth } from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { checkOutlook } from '_core/helpers/outlook'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export type ContactsProps = {
  items: UserCompanyContactListItem[]
  loading: boolean
  total: number
  paging: GridTypes['paging']
  setPageSize: GridTypes['setPageSize']
  rowsPerPageOptions?: GridTypes['rowsPerPageOptions']
  updateSort: (val: ScoreType | StatSortType) => void
  download: { fileName: string; requestBinary: (data: DownloadBulkParams) => Promise<Blob | undefined> }
  months: ActivityStatsMonth[]
}

const useStyles = makeStyles()((theme) => ({
  input: {
    marginRight: theme.spacing(0.5)
  }
}))

const Contacts = (props: ContactsProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const { pathname } = useLocation()
  const { from, to } = useParams<{ from: string; to: string }>()
  const { isExact: enableMiddleView } = matchPath(pathname, `${Paths._relationships}/${from}/companies/${to}/activityStats`) || {}

  const upSmall = useWide('sm')
  const wideStrict = useWide('lg')
  const { queryParams } = useSearchQuery<GridParams & { viewMode: ViewModeType }>()
  const { viewMode } = queryParams
  const isExpandedView = viewMode === 'expanded'

  const { userActivitiesAccess } = useActivitiesAccess([from, to]) || {}
  const { isStatsWidgetVisible = false } = userActivitiesAccess || {}

  const md5s = useMemo(() => props.items.map(({ PersonMd5 }) => PersonMd5), [props.loading, props.items.length])

  const statsPayloads = useMemo(
    () => ({
      meetings: {
        pagingBy: 'FocusedPersonId',
        focusedRole: 'Any',
        company2Ids: [to],
        focusPersonIds: md5s
      },
      inbound: {
        pagingBy: 'Person2Id',
        focusedRole: 'Sender',
        focusCompanyIds: [to],
        person2Ids: md5s
      },
      outbound: {
        pagingBy: 'FocusedPersonId',
        focusedRole: 'Sender',
        company2Ids: [to],
        focusPersonIds: md5s
      }
    }),
    [to, md5s]
  )

  const { stats } = useActivitiesStats(from ? statsPayloads : null, md5s, props.months)

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 200, width: 200 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: inboundCountColumn, condition: isStatsWidgetVisible },
        { column: outboundCountColumn, condition: isStatsWidgetVisible },
        { column: meetingsCountColumn, condition: isStatsWidgetVisible },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [wideStrict, isStatsWidgetVisible]
  )

  const itemVariant = isExpandedView ? 'card' : 'list'

  const items = useMemo(
    () =>
      props.items?.map((person, idx) => {
        const formerJob = person.IsFormerJob
        const workExperience = {
          companyIdentity: person.CompanyMd5,
          companyName: person.JobCompanyName || person.CorpLevelCompanyName,
          jobTitle: person.TitleText,
          currentAsOf: person.JobCurrentAsOf
        }

        const { inbound, outbound, meetings } = stats?.[idx] || {}

        const statsData = isStatsWidgetVisible
          ? {
              inbound: stats ? inbound?.count || null : -1,
              outbound: stats ? outbound?.count || null : -1,
              meetings: stats ? meetings?.count || null : -1
            }
          : {}

        return {
          id: person.MyUserKeyMd5 || person.PersonMd5,
          name: person.PersonNameText || 'No name',
          byline: person.TitleText || '',
          byline2: person.JobCompanyName || person.CorpLevelCompanyName || '',
          byline3: <ExpandableDetails isExpandedView={isExpandedView} content={<StatsContent isExpandedView={isExpandedView} {...statsData} />} />,
          score: person.UserKnowsPersonScore,
          link: `${Paths._relationships}/${from}/people/${person.MyUserKeyMd5 || person.PersonMd5}`,
          title: person.TitleText || '',
          formerJob,
          company: {
            name: person.JobCompanyName || person.CorpLevelCompanyName || '',
            link: `${Paths._companies}/${person.CompanyMd5}`,
            sidepanel: true
          },
          workExperience,
          sidepanel: true,
          icons: (
            <Box display="flex" alignItems="center" justifyContent="end" mr={0.5}>
              {formerJob && (
                <Box ml={1.5}>
                  <AffiliationPopover workExperience={workExperience} icon={['far', 'address-book']} />
                </Box>
              )}
            </Box>
          ),
          variant: 'expandable',
          ...statsData
        }
      }),
    [props.loading, stats, from, isExpandedView, isStatsWidgetVisible]
  )

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const DownloadAll = <DownloadBulkControl totalRows={props.total} disabled={props.loading} download={props.download} />

  const DownloadSelected = <Download disabled={!selected.length} leadsList={selected} leadType="Person" />

  const gridEl = (
    <DataGrid
      rows={items}
      columns={columns}
      controls={[DownloadAll, DownloadSelected]}
      loading={props.loading}
      setPageSize={props.setPageSize}
      rowsPerPageOptions={props.rowsPerPageOptions}
      checkboxSelection
      paging={props.paging}
      total={props.total}
      onSelect={handleSelect}
      rowHeight={upSmall && !wideStrict ? 80 : 52}
    />
  )

  const listEl = (
    <Repeater direction="vertical" variant={itemVariant} component={ProfileItem} skeleton={{ size: 10, loading: props.loading }} items={items} />
  )

  return (
    <>
      {enableMiddleView && (
        <>
          <Middle includeWide>{gridEl}</Middle>
          <NarrowStrict>{listEl}</NarrowStrict>
        </>
      )}
      {!enableMiddleView && (
        <>
          <Wide>{gridEl}</Wide>
          <Narrow>{listEl}</Narrow>
        </>
      )}
    </>
  )
}

export const Contcts = ({
  rowsPerPageOptions,
  months,
  autoHideOnScroll,
  onLoading
}: Pick<ComponentProps<typeof Contacts>, 'rowsPerPageOptions' | 'months'> & {
  autoHideOnScroll?: boolean
  onLoading?: (loading: boolean, result: { total_item_count: number }) => void
}) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { queryParams, updateQuery } = useSearchQuery<GridParams & { from?: string; to?: string }>()
  const { keyword, rowsPerPage = rowsPerPageOptions ? '5' : '10', sort } = queryParams
  const { teamContextValue } = useContext(TeamContext)
  const middle = useWide('sm')

  const onPageSizeChange = (pageSize: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage: pageSize })
  }

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    SortBy: sort || 'ScoreDesc',
    SortScoreType: 'PersonalScore'
  }

  const download = {
    fileName: 'company_contacts',
    requestBinary: (data: DownloadBulkParams) => getBinary(`/companies/${to}/peoplexl`, { ...payload, ...data })
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: Pick<ContactsProps, 'download' | 'rowsPerPageOptions' | 'months'> } }>
      url={mergeUrlWithParams(`/users/${from}/companies/${to}/people`, payload)}
      component={Contacts}
      addprops={{ download, rowsPerPageOptions, months }}
      updatePageSize={onPageSizeChange}
      pageSize={+rowsPerPage}
      list
      infinite={!middle}
      autoHideOnScroll={autoHideOnScroll}
      search
      keepMounted
      onLoading={onLoading}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="company_contacts"
    />
  )
}

const RelationshipContacts = () => {
  const params = useParams<{ from: string; to: string }>()
  const { setSubHeader } = useContext(LayoutContext)
  const { queryParams, updateQuery } = useSearchQuery<GridParams & { from?: string; to?: string; viewMode: ViewModeType }>()
  const { from, to, viewMode } = queryParams
  const { classes } = useStyles()
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()
  const [total, setTotal] = useState<number>()

  const { userActivitiesAccess } = useActivitiesAccess([params.from, params.to]) || {}
  const { isStatsWidgetVisible = false } = userActivitiesAccess || {}

  useEffect(() => {
    setSubHeader(`Contacts ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  useEffect(() => {
    if (!viewMode && isStatsWidgetVisible) {
      updateQuery({
        from: from || getUTC().startOf('year').toISOString(),
        to: to || getUTC().endOf('year').toISOString(),
        viewMode: 'collapsed'
      })
    }
  }, [from, to, isStatsWidgetVisible, viewMode, updateQuery])

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const toggleView = () => {
    updateQuery({ viewMode: viewMode === 'collapsed' ? 'expanded' : 'collapsed' })
  }

  const onLoading = (loading: boolean, data?: { total_item_count: number }) => {
    if (data) {
      setTotal(data.total_item_count)
    }
  }

  return (
    <>
      <Wide>
        <Box display="flex" height={1} minHeight={'calc(100vh - 296px)'}>
          <Grid>
            <Grid.Heading title="Contacts">
              <Box display="flex" alignItems="center">
                <SearchInput placeholder="Search for contacts" variant="collapsed" opened />
              </Box>
            </Grid.Heading>
            {isStatsWidgetVisible && <ActivityStatsPeriodMsg title={chartData.gridMessage} />}
            <Contcts months={chartData.months} onLoading={onLoading} />
          </Grid>
        </Box>
      </Wide>
      <Narrow>
        <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
          <Box display="flex">
            <SearchInput placeholder="Search for contacts" variant="collapsed" opened wrapperClassName={isStatsWidgetVisible ? classes.input : ''} />
            {isStatsWidgetVisible && (
              <IconButton
                hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
                onClick={toggleView}
                size="small"
                icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
              />
            )}
          </Box>
          {viewMode === 'expanded' && isStatsWidgetVisible && <ActivityStatsPeriodMsg title={chartData.gridMessage} />}
        </Widget>
        <Contcts months={chartData.months} autoHideOnScroll onLoading={onLoading} />
      </Narrow>
    </>
  )
}

export default RelationshipContacts
