import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import useStyles from './styles'

const IntroducersWidget = (props: { loading: boolean; total?: number; link: string; items: ComponentProps<typeof Avatar>[]; entityName: string }) => {
  const { classes } = useStyles()
  const { items, total, link, entityName, loading } = props

  return (
    <Widget className={classes.widget}>
      <Heading underlined title="Introducers" count={total} icon={['far', 'address-book']} link={total ? link : ''} />
      {(!!total || loading) && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: props.loading }} items={items} />}
      {!total && !loading && (
        <Empty
          className={classes.empty}
          subTitle={`${entityName} has no introducers`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default IntroducersWidget
