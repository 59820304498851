import React, { useState, useRef, useMemo, ReactElement, cloneElement, ComponentProps, PropsWithChildren } from 'react'

import { Box } from '@mui/material'
import { GRID_CHECKBOX_SELECTION_COL_DEF as Column, GridSelectionModel, GridSortItem, GridSortModel, GridColDef } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

import Download from '_core/components/Download'
import DownloadBulkControl from '_core/components/DownloadBulkControl'
import ExpandableDetails from '_core/components/ExpandableDetails'
import { Controller as FilterController } from '_core/components/filters'
import { PeopleFiltersType } from '_core/components/filters/People'
import { DataGrid, GridTypes } from '_core/components/grid'
import GridHeaderButton from '_core/components/GridHeaderButton'
import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import { Middle, Narrow, NarrowStrict, useWide, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import { activityStatsBreakdownOnboardingTarget } from '_core/components/onboarding/ActivityStats'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'
import SidepanelLink from '_core/components/SidepanelLink'
import { sortMap, getSortModels } from '_core/components/sort'
import Sort, { PeopleSortProps, Controller as SortController } from '_core/components/sort/People'
import StatsContent from '_core/components/StatsContent'
import TagsGroup from '_core/components/TagsGroup'
import Widget from '_core/components/Widget'

import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useIntroducersListPayloads, { IntroducerItem } from '_core/hooks/useIntroducersListPayloads'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSelectedInfoHandler from '_core/hooks/useSelectedInfoHandler'
import { groupTags } from '_core/hooks/useTagsManager'

import { checkOutlook } from '_core/helpers/outlook'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles<{ filtersOpened: boolean; viewMode: ViewModeType | undefined }>()((theme, { filtersOpened, viewMode }) => ({
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: filtersOpened ? 'calc(100% - 39px)' : `calc(100% - ${39 * 3}px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened ? 39 : 39 * 3,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${viewMode === 'collapsed' ? theme.spacing(2) : 0}`
  }
}))

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<PeopleFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
  sortProps: Pick<PeopleSortProps, 'items' | 'sort' | 'updateSort' | 'excludeEmpty' | 'toggleExclude'>
  viewProps: {
    viewMode?: ViewModeType
    updateViewMode: (val: ViewModeType) => void
  }
  searchPlaceholder: string
}

export const Heading = ({ filtersProps, sortProps, viewProps, searchPlaceholder, filters, children }: PropsWithChildren<HeadingProps>) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { viewMode, updateViewMode } = viewProps
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [sortCollapsed, setSortCollapsed] = useState(true)
  const { classes } = useStyles({ filtersOpened, viewMode })

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  const toggleView = () => updateViewMode(viewMode === 'collapsed' ? 'expanded' : 'collapsed')

  return (
    <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
      <div ref={anchorRef}>
        <Box display="flex">
          <SearchInput disabled={disabled} placeholder={searchPlaceholder} variant="collapsed" opened wrapperClassName={classes.input} />
          <Box className={classes.icons}>
            <IconButton
              hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
              onClick={toggleView}
              size="small"
              icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
              className={classes.displayVariant}
              disabled={disabled}
            />
            <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} className={classes.displayVariant} disabled={disabled} />
            <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
          </Box>
        </Box>
      </div>
      {cloneElement(filters, { anchorEl: anchorRef.current })}
      <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
      {children}
    </Widget>
  )
}

export type IntroducersListProps = {
  items: IntroducerItem[]
  loading: boolean
  sidepanel: SidepanelType
  total: number
  updateSort: (val: ScoreType | StatSortType) => void
  sort?: ScoreType | StatSortType
  setPageSize: GridTypes['setPageSize']
  paging: GridTypes['paging']
  onResize: GridTypes['onResize']
  sortByField?: GridSortItem['field']
  forceNarrow?: boolean
  viewMode: ViewModeType
  columns: GridColDef[]
  rowsPerPageOptions?: GridTypes['rowsPerPageOptions']
  download: { fileName: string; requestBinary: (data: DownloadBulkParams) => Promise<Blob | undefined> }
  enableMiddleView?: boolean
}

const initGridState = {
  pinnedColumns: { left: [Column.field, 'name'], right: [Column.field, 'score'] }
}

const IntroducersList = (props: IntroducersListProps) => {
  const upSmall = useWide('sm')
  const wideStrict = useWide('lg')

  const { selected, selectedIDs, selectedNames, handleSelectedInfo } = useSelectedInfoHandler('people')
  const { queryParams } = useSearchQuery<IntroducersPageParams>()
  const enableDownloading = useEnableDownloading()
  const { sortByField = 'score', viewMode } = props
  const { withTagsList } = useIntroducersListPayloads(props.items)
  const isExpandedView = viewMode === 'expanded'

  const visibleStatsContent =
    !(viewMode === 'collapsed' && sortByField === 'score') && !!props.columns.find(({ field }) => field === 'lastInboundMsg' || 'inboundCount')

  const items = useMemo(
    () =>
      props.items?.map((introducer) => {
        const tags = withTagsList?.find((item) => item.identifier === introducer.id)?.tags
        const visibleTagsContent = !!tags?.length

        const showAllTagsLink = `${Paths._people}/${introducer.id}/tags?name=${introducer.name}`
        const editTagsLink = `${Paths._people}/${introducer.id}/tags/edit?name=${introducer.name}`

        return {
          ...introducer,
          tags,
          showAllTagsLink,
          editTagsLink,
          byline3: (
            <ExpandableDetails
              isExpandedView={isExpandedView}
              collapsedSizes={{
                contentStart: visibleTagsContent ? 32 : 0,
                content: visibleStatsContent ? 26 : 0
              }}
              contentStart={
                visibleTagsContent && (
                  <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                    items={tags}
                    tagComponent={InternalTagPopover}
                    renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                      <ExtraTagsPopover
                        triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                        items={groupTags(tags)}
                      />
                    )}
                    max={upSmall ? 4 : 2}
                    title={
                      isExpandedView ? (
                        <Typography color="text.secondary" semiBold>
                          Tags
                        </Typography>
                      ) : null
                    }
                    addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!tags?.length} />}
                    showIcon={isExpandedView}
                  />
                )
              }
              content={
                visibleStatsContent && (
                  <StatsContent
                    lastInbound={introducer.lastInbound}
                    lastOutbound={introducer.lastOutbound}
                    lastMeeting={introducer.lastMeeting}
                    nextFutureMeeting={introducer.nextFutureMeeting}
                    inbound={introducer.inbound}
                    outbound={introducer.outbound}
                    meetings={introducer.meetings}
                    isExpandedView={isExpandedView}
                    sortByField={sortByField}
                  />
                )
              }
            />
          )
        }
      }),
    [props.items, withTagsList, isExpandedView, visibleStatsContent, upSmall, sortByField]
  )

  const updateSort = (model: GridSortModel) => {
    if (model?.length && queryParams.sort) {
      const { field, sort: newSort } = model[0]
      if (newSort && queryParams.sort !== sortMap[field][newSort]) {
        const sort = sortMap[field][newSort]
        props.updateSort(sort)
      }
    }
  }

  const handleSelect = (selectionModel: GridSelectionModel) => {
    handleSelectedInfo(items, selectionModel as string[])
  }

  const DownloadAll = <DownloadBulkControl totalRows={props.total} disabled={props.loading} download={props.download} />
  const DownloadSelected = (
    <Download disabled={!selected.length} leadsList={selectedIDs.map((el: string) => el.replaceAll('%27', "'"))} leadType="Person" />
  )

  const AddTags = (
    <SidepanelLink
      linkProps={{
        to: selected.length
          ? selected.length === 1
            ? mergeUrlWithParams(`${Paths._people}/${selectedIDs[0]}/tags/edit`, {
                name: selectedNames?.join(', ')
              })
            : mergeUrlWithParams(`${Paths._people}/add-tags`, {
                keys: selectedIDs?.join(','),
                names: selectedNames?.join(', ')
              })
          : ''
      }}
      sidepanel={true}
    >
      <GridHeaderButton disabled={!selected.length} icon={['far', 'tag']} fontSize={21} hint={`${selected.length === 1 ? 'Edit' : 'Add'} tags`} />
    </SidepanelLink>
  )

  const controls =
    typeof enableDownloading === 'boolean'
      ? [{ control: DownloadAll, condition: enableDownloading }, { control: DownloadSelected, condition: enableDownloading }, { control: AddTags }]
          .filter((item) => (typeof item.condition === 'boolean' ? item.condition : true))
          .map((item) => item.control)
      : []

  const models: GridSortModel = useMemo(() => getSortModels(props.sort), [props.sort])

  const itemVariant = isExpandedView ? 'card' : 'list'

  const gridEl = (
    <DataGrid
      rows={!props.loading ? items : []}
      columns={props.columns}
      controls={controls}
      setSortModel={updateSort}
      sortModel={models.filter((m) => m.field === sortByField)}
      onSelect={handleSelect}
      loading={props.loading}
      setPageSize={props.setPageSize}
      onResize={props.onResize}
      checkboxSelection={!!controls.length}
      paging={props.paging}
      total={props.total}
      initialState={initGridState}
      rowsPerPageOptions={props.rowsPerPageOptions}
      rowHeight={upSmall && !wideStrict ? 80 : 52}
      className={activityStatsBreakdownOnboardingTarget}
    />
  )

  const listEl = (
    <Repeater
      direction="vertical"
      variant={itemVariant}
      component={ProfileItem}
      skeleton={{ size: 20, loading: props.loading }}
      items={!props.loading ? items : []}
    />
  )

  return (
    <>
      {props.enableMiddleView && (
        <>
          <Middle includeWide>{gridEl}</Middle>
          <NarrowStrict>{listEl}</NarrowStrict>
        </>
      )}
      {!props.enableMiddleView && (
        <>
          <Wide>{gridEl}</Wide>
          <Narrow>{listEl}</Narrow>
        </>
      )}
    </>
  )
}

export default IntroducersList
