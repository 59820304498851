import { useEffect, useContext, useState, useRef, useMemo } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'
import { useForm } from 'react-hook-form'
import { useParams, useLocation } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import DownloadBulkDialog, { DownloadTemplateControl, templates } from '_core/components/dialogs/DownloadBulk'
import Filters, { getColleagueFlag } from '_core/components/filters/People'
import {
  companyColumn,
  jobTitleColumn,
  nameColumn,
  bestIntroducerColumn,
  scoreColumn,
  lastMeetingColumn,
  lastInboundColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow } from '_core/components/layout'
import PeopleList, { PeopleListProps, Heading } from '_core/components/PeopleList'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import useDownloadBulk, { DownloadBulkType, DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import usePeopleUserSettings from '_core/hooks/usePeopleUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams, put } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = Pick<PeopleListProps, 'sort' | 'sortByField' | 'updateSort' | 'forceLoading' | 'viewMode' | 'columns' | 'enableDownloading'> & {
  download: {
    fileName: DownloadBulkType['fileName']
    requestBinary: (params: DownloadBulkParams & { template: 'default' | 'upload' }) => Promise<Blob | undefined>
  }
}

type DownLoadBulkInit = { compactTemplate: boolean; numberOfRows: number; startingRow: number }

const DownloadAllControl = ({
  total,
  loading,
  download
}: {
  total: number
  loading: boolean
  download: {
    fileName: DownloadBulkType['fileName']
    requestBinary: (params: DownloadBulkParams & { template: 'default' | 'upload' }) => Promise<Blob | undefined>
  }
}) => {
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  const {
    control,
    setValue: setTemplateValue,
    getValues: getTemplateValue
  } = useForm<{
    template: (typeof templates)[number]['value']
  }>({ mode: 'onChange', defaultValues: { template: 'default' } })

  const {
    register,
    getValues,
    setValue,
    watch,
    errors,
    handleSubmit: downloadSubmit
  } = useDownloadBulk({
    defaultValues: { count: undefined, rows: undefined },
    fileName: download.fileName,
    shouldUnregister: false,
    requestBinary: (params: DownloadBulkParams) => download.requestBinary({ ...params, template: getTemplateValue('template') })
  })

  const { count } = watch()

  const setInitial = ({ compactTemplate, numberOfRows, startingRow }: DownLoadBulkInit) => {
    setValue('count', numberOfRows || 500)
    setValue('rows', startingRow || 1)
    setTemplateValue('template', compactTemplate ? 'upload' : 'default')
  }

  const handleSubmit = async (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    e?.preventDefault()
    closeDialog()
    await put<DownLoadBulkInit>('/usersettings/bulkdownload', {
      compactTemplate: getTemplateValue('template') === 'upload',
      numberOfRows: getValues('count'),
      startingRow: getValues('rows')
    })
    return downloadSubmit(e)
  }

  return (
    <UserSettings setInitial={setInitial} endpoint="/usersettings/bulkdownload">
      <DownloadBulkDialog.TriggerEl open={openDialog} disabled={loading || typeof count === 'undefined'} />
      <DownloadBulkDialog
        totalRows={total}
        register={register}
        getValues={getValues}
        errors={errors}
        handleSubmit={handleSubmit}
        isOpened={isDialogOpened}
        close={closeDialog}
        templateControl={<DownloadTemplateControl control={control} />}
      />
    </UserSettings>
  )
}

const People = ({
  download,
  ...props
}: Modify<PeopleListProps, { items: UserPeopleListItem[] }> & {
  download: {
    fileName: DownloadBulkType['fileName']
    requestBinary: (params: DownloadBulkParams & { template: 'default' | 'upload' }) => Promise<Blob | undefined>
  }
}) => {
  const { setSubHeader } = useContext(LayoutContext)

  const items = useMemo(
    () =>
      props.items?.map((person) => {
        const workExperience = {
          companyIdentity: person.BestJobCompanyMd5 || '',
          companyName: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
          jobTitle: person.BestJobTitleText || '',
          currentAsOf: person.BestJobCurrentAsOf || ''
        }

        const formerJob = !!person.BestJobIsFormer
        const id = person.MyUserKeyMd5 || person.PersonMd5 || person.BestEmailAddrText?.replaceAll("'", '%27')
        const lastInbound = person.Stats?.LastInboundMsg || ''
        const lastOutbound = person.Stats?.LastOutboundMsg || ''
        const lastMeeting = person.Stats?.LastMeeting || ''
        const nextFutureMeeting = person.Stats?.NextFutureMeeting || ''

        return {
          id,
          name: person.PersonNameText || person.BestEmailAddrText,
          byline: person.BestJobTitleText || '',
          byline2: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
          title: person.BestJobTitleText || '',
          score: person.Score,
          link: `${Paths._relationships}/${person.UserKeyMd5}/people/${person.PersonMd5}`,
          email: person.BestEmailAddrText,
          company: {
            name: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
            link: person.BestJobCompanyMd5 ? `${Paths._companies}/${person.BestJobCompanyMd5}` : '',
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: person.BestKnowerNameText || '',
            link: person.BestKnowerUserKeyMd5 ? `${Paths._relationships}/${person.BestKnowerUserKeyMd5}/people/${id}` : '',
            sidepanel: true as SidepanelType
          },
          formerJob,
          workExperience,
          sidepanel: true as SidepanelType,
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          variant: 'expandable',
          privacy: person.Privacy,
          tags: person.Tags || []
        }
      }),
    [props.loading, props.items.length]
  )

  useEffect(() => {
    setSubHeader(`People ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  return (
    <PeopleList
      {...props}
      items={items}
      downloadAllControl={<DownloadAllControl total={props.total} loading={props.loading} download={download} />}
    />
  )
}

type CProps = {
  enableDownloading: PeopleListProps['enableDownloading']
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

const Component = (props: CProps) => {
  const { id } = useParams<{ id: string }>()
  const { search } = useLocation()
  const { teamContextValue } = useContext(TeamContext)
  const [forceContentLoading, setForceContentLoading] = useState<boolean>(false)

  const { queryParams } = useSearchQuery<ContributorsPeoplePageParams, { modifyProps: [{ checked: IncludePeopleType[] }] }>(['checked'])
  const { checked = [], sort, viewMode, rowsPerPage = '10', keyword, and, includeTags, excludeTags } = queryParams

  const colleagueFlag = getColleagueFlag(checked)
  const prevColleagueFlag = useRef<ColleagueFlag>()
  useEffect(() => {
    if (prevColleagueFlag.current === colleagueFlag) {
      setForceContentLoading(true)
      setTimeout(() => setForceContentLoading(false), 1000)
    }
    prevColleagueFlag.current = colleagueFlag
  }, [checked.length, colleagueFlag, setForceContentLoading])

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: any }[] = [
    { name: 'TeamNumber', value: teamContextValue?.teamNumber },
    { name: 'ColleagueFlag', value: colleagueFlag },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WithPersonTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params
    .filter(({ value }) => !!value)
    .reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {} as { [key: string]: string })

  const url = search ? mergeUrlWithParams(`/users/${id}/people`, payload) : null

  const download = {
    fileName: 'user_people',
    requestBinary: ({ template, ...downloadParams }: DownloadBulkParams & { template: 'default' | 'upload' }) => {
      return getBinary('/people/xl', {
        ...payload,
        ...downloadParams,
        relationOfUserKey: id,
        numIntroducers: '5',
        numEmails: '5',
        numPhones: '3',
        numJobs: '3',
        returnInUploaderTemplate: `${template === 'upload'}`
      })
    }
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: AddProps } }>
      url={url}
      addprops={{
        forceLoading: forceContentLoading,
        sort,
        enableDownloading: props.enableDownloading,
        updateSort: props.updateSort,
        sortByField: sortByField || 'score',
        viewMode: viewMode || 'collapsed',
        columns: props.columns,
        download
      }}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      updatePageSize={props.onPageSizeChange}
      pageSize={+rowsPerPage}
      onLoading={props.onLoading}
      component={People}
      keepMounted
      autoHideOnScroll
      list
      infinite
      search
      id="person_people"
    />
  )
}

const UserPeople = () => {
  const { queryParams, updateQuery } = useSearchQuery<ContributorsPeoplePageParams, { modifyProps: [{ checked: IncludePeopleType[] }] }>(['checked'])

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const { sort, viewMode, excludeEmpty } = queryParams

  const { dataVisibility } = useUserDataVisibility()
  const { allowFilteringByInteractions, preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = usePeopleUserSettings({
    allowFilteringByInteractions,
    isContributor: true
  })

  const disabled = filtersLoading || interactionsFiltersShown === null

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    isContributor: true,
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: jobTitleColumn },
        { column: companyColumn },
        { column: bestIntroducerColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for people'
  return (
    <UserSettings endpoint="/usersettings/peoplesearchfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="People"
        gridHeadingIcon={['far', 'user']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        component={
          <Component
            enableDownloading={enableDownloading}
            columns={columns}
            onLoading={onLoading}
            updateSort={updateSort}
            onPageSizeChange={onPageSizeChange}
          />
        }
      />
    </UserSettings>
  )
}

export default UserPeople
