import { CSSProperties, ElementType, ReactNode, RefObject } from 'react'

import { Link } from 'react-router-dom'

import useSidepanelLink, { SidepanelLinkProps } from '_core/hooks/useSidepanelLink'

const SidepanelLink = (
  props: SidepanelLinkProps & {
    sidepanel?: SidepanelType
    linkRef?: RefObject<HTMLAnchorElement>
    linkProps: { to: string; index?: number; style?: CSSProperties; className?: string }
    children: ReactNode
    componentTag?: ElementType
  }
) => {
  const {
    linkProps: { to, index, style, className: linkPropsClassName },
    sidepanel = true,
    linkRef,
    onClick,
    window,
    preventClickOnSame,
    strict,
    children
  } = props
  const { handleClick, className } = useSidepanelLink({ onClick, window, preventClickOnSame, strict })

  if (!to) {
    return <>{children}</>
  }

  return (
    <Link
      ref={linkRef}
      onClick={(e) => handleClick(e, sidepanel, { to, index })}
      to={to}
      style={style}
      className={`${className} ${linkPropsClassName}`}
    >
      {children}
    </Link>
  )
}

export default SidepanelLink
