import { useMemo, useContext, ComponentProps, useEffect } from 'react'

import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Grid from '_core/components/grid'
import { Column, Columns, Narrow, NarrowStrict, Middle, Wide } from '_core/components/layout'
import SearchInput from '_core/components/SearchInput'
import Widget from '_core/components/Widget'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import ContactsWidget from '_core/components/widgets/Contacts'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayload from '_core/hooks/useCompanyActivitiesPayloads'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import { Contcts } from './contacts'

const useStyles = makeStyles()((theme) => ({
  contactsSection: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 350
    }
  },
  grid: {
    height: '100%'
  }
}))

const ContctWidget = (props: { items: UserCompanyContactListItem[]; loading: boolean; total: number; companyName: string }) => {
  const { from: contributorId, to: company2Id } = useParams<{ from: string; to: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()

  const { from, to } = queryParams

  return (
    <ContactsWidget
      loading={props.loading}
      entityName={props.companyName}
      link={stringifyUrl(`${Paths._relationships}/${contributorId}/companies/${company2Id}/contacts`, { from, to })}
      total={props.total}
      items={props.items.slice(0, 5).map((contact) => ({
        name: contact.PersonNameText || 'No name',
        score: contact.UserKnowsPersonScore,
        link: `${Paths._people}/${contact.PersonMd5}`,
        sidepanel: true
      }))}
    />
  )
}

const ContactActivityStatisticsPage = ({ companyName }: { companyName: string }) => {
  const { setSubHeader } = useContext(LayoutContext)
  const { from: contributorId, to: company2Id } = useParams<{ from: string; to: string }>()
  const { classes } = useStyles()

  useEffect(() => {
    setSubHeader('Activity statistics')
  }, [setSubHeader])

  const { teamContextValue } = useContext(TeamContext)

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()

  const statsPayloads = useCompanyActivitiesPayload(company2Id, contributorId)
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { sort, from: fromUTC = '', to: toUTC = '', stackColumns } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { userActivitiesAccess } = useActivitiesAccess([contributorId, company2Id]) || {}
  const { isDetailedActivityFromStatsWidgetVisible = false } = userActivitiesAccess || {}

  const { stats } = useActivitiesStats(
    contributorId ? statsPayloads : null,
    useMemo(() => [company2Id], [company2Id]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    handleChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    if (stackColumns) {
      handleChange({ stackColumns: `${!JSON.parse(stackColumns)}` })
    }
  }

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    SortBy: sort || 'ScoreDesc',
    SortScoreType: 'PersonalScore'
  }

  const gridEl = (
    <Grid padding={0} className={classes.grid}>
      <Grid.Heading title="Contacts">
        <Box display="flex" alignItems="center">
          <SearchInput placeholder="Search for contacts" variant="collapsed" opened />
        </Box>
      </Grid.Heading>
      <Contcts rowsPerPageOptions={[3, 5, 10]} months={chartData.months} />
    </Grid>
  )

  return (
    <UserSettings endpoint={!stackColumns ? '/usersettings/activitiesstats' : ''} setInitial={setInitial}>
      <Columns>
        <Column xs={12} md={12}>
          <ActivityStatsWidget
            stats={stats}
            stack={stackColumns === 'true'}
            detailsLink={`${Paths._relationships}/${contributorId}/companies/${company2Id}/activities`}
            isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
            updatePeriod={updatePeriod}
            toggleStack={toggleStack}
            {...chartData}
          />
        </Column>
        <Column xs={12} md={12} className={classes.contactsSection}>
          <Wide>{gridEl}</Wide>
          <Narrow>
            <Middle>
              <Widget>{gridEl}</Widget>
            </Middle>
            <NarrowStrict>
              <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof ContctWidget>, 'companyName'> } }>
                url={mergeUrlWithParams(`/users/${contributorId}/companies/${company2Id}/people`, payload)}
                addprops={{ companyName }}
                component={ContctWidget}
                list
                keepMounted
                empty="No results found"
                id="company_contacts"
              />
            </NarrowStrict>
          </Narrow>
        </Column>
      </Columns>
    </UserSettings>
  )
}

export default ContactActivityStatisticsPage
