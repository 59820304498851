import { PropsWithChildren } from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { IconPrefix } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import CopyButton from '_core/components/CopyButton'
import InformAboutIdentityDialog, { getReasonLabel, getReason } from '_core/components/dialogs/InformAboutIdentity'
import SidepanelLink from '_core/components/SidepanelLink'

import useDialog from '_core/hooks/useDialog'

const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
      '&:first-of-type': {
        maxWidth: `calc(100% - ${39 * 3}px)`
      }
    }
  },
  icon: {
    fontSize: 13
  }
}))

const ExternalLink = (props: PropsWithChildren<{ link?: string }>) => {
  if (props.link) {
    return (
      <Link to={{ pathname: `${props.link}` }} target="_blank">
        {props.children}
      </Link>
    )
  }

  return <>{props.children}</>
}

const IdentifierLabel = (props: {
  identity: string
  name: string
  externalLink?: string
  icon?: [IconPrefix, IconName]
  type: AuditIdentifierType
  className?: string
  submit: (identity: string, value: number) => Promise<unknown>
  sourceLink: string
  auditLink: string
}) => {
  const { classes, cx } = useStyles()
  const { identity, name, icon, type, submit, sourceLink, auditLink } = props
  const { isDialogOpened, openDialog, closeDialog, successMode, openSuccess } = useDialog()

  const { junk, notBelongsTo, shared } = getReasonLabel(type)

  const handleSubmit = (value: number) => {
    return submit(identity, value)
  }

  return (
    <Skeleton condition={!identity} width="100%" height={20}>
      <Typography variant="body1" component="div" className={cx(classes.iconWrapper, props.className)}>
        <Box>
          {icon?.length && <FontAwesomeIcon color="rgba(27, 149, 187)" icon={icon} size="sm" style={{ marginRight: 8 }} />}
          <ExternalLink link={props.externalLink}>
            <Tooltip title={identity}>
              <Typography color={props.externalLink ? 'primary' : 'text.primary'} component="span" noWrap>
                {identity}
              </Typography>
            </Tooltip>
          </ExternalLink>
        </Box>
        <Box>
          <SidepanelLink linkProps={{ to: sourceLink }}>
            <IconButton icon={['far', 'list-tree']} classes={{ root: classes.icon }} hint="Sources" size="small" disablePY disablePL />
          </SidepanelLink>
          <CopyButton text={identity} disablePY />
          <InformAboutIdentityDialog.TriggerEl onClick={openDialog} hint={`Inform us about this ${getReason(type).label}`} />
        </Box>
      </Typography>
      <InformAboutIdentityDialog
        identity={identity}
        opened={isDialogOpened}
        close={closeDialog}
        submit={handleSubmit}
        junk={junk}
        shared={shared}
        notBelongsTo={notBelongsTo(name, identity)}
        auditLink={auditLink}
        openSuccess={openSuccess}
        successMode={successMode}
      />
    </Skeleton>
  )
}

export default IdentifierLabel
