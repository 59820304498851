import React from 'react'

import { Box } from '@mui/material'

import Empty from '_core/components/Empty'
import Widget from '_core/components/Widget'

const AuditEmpty = () => (
  <Widget>
    <Box height="calc(100vh - 204px)">
      <Empty title="No audit identities found" />
    </Box>
  </Widget>
)

export default AuditEmpty
