import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Success from '_core/components/Success'
import Widget from '_core/components/Widget'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

const InvalidTupleSuccessPage = () => {
  const match = useRouteMatch()
  const handleClose = useSidepanelClose()
  const url = `${match.url}`.replace('/success', '')

  return (
    <Widget>
      <Box height="calc(100vh - 160px)">
        <Success variant="centered" text={<Typography>The selected tuples was successfully marked as invalid.</Typography>}>
          <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
            <Link to={url}>
              <Button variant="outlined" startIcon={<FontAwesomeIcon icon={['far', 'list-check']} style={{ fontSize: 14 }} />}>
                View other tuples
              </Button>
            </Link>
            <Button variant="text" onClick={handleClose} color="secondary">
              Close
            </Button>
          </Box>
        </Success>
      </Box>
    </Widget>
  )
}

export default InvalidTupleSuccessPage
