import React, { memo, useContext, useMemo, useState } from 'react'

import { Route, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import TuplesListPage from '_pages/companies/[id]/audit/tuples'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AuditTopBarActions from '_core/components/audit/AuditTopBarActions'
import CompanyAudit, { ActionProps, AddProps } from '_core/components/audit/CompanyAudit'
import { PickerButton } from '_core/components/Picker'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import { useTabs } from '_core/hooks/useTabs'

import DynamicEntity from '_core/DynamicEntity'

import TabSet from 'SharedComponents/TabSet'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  tabSet: {
    padding: 0,
    marginBottom: `-${theme.spacing(2)}`
  }
}))

const Tabs = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()

  const tabs = useMemo(
    () => [
      {
        label: 'Identifiers',
        routesMatch: [`${Paths._companies}/${id}/audit`]
      },
      {
        label: 'Tuples',
        routesMatch: [`${Paths._companies}/${id}/audit/tuples`, `${Paths._companies}/${id}/audit/tuples/*`]
      }
    ],
    [id]
  )
  const { tabIndex, setTabIndex, tabChanged } = useTabs(tabs)

  return (
    <Widget>
      <TabSet className={classes.tabSet} tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} onChange={tabChanged} />
    </Widget>
  )
})

const Heading = ({ step, actionProps }: Pick<AddProps, 'step'> & { actionProps: ActionProps }) => {
  const Action = actionProps ? (
    <AuditTopBarActions
      isDirty={actionProps.isDirty}
      reset={actionProps.handleReset}
      pickerButton={<PickerButton entity="companies" onSearchClick={actionProps.onSearchClick} />}
    />
  ) : null

  return (
    <>
      {step !== 3 && (
        <>
          <Topbar nativeBack action={Action} />
          <Tabs />
        </>
      )}
    </>
  )
}

const CompanyAuditPages = () => {
  const { id } = useParams<{ id: string }>()
  const [step, setStep] = useState<number>(0)
  const [actionProps, setActionProps] = useState<ActionProps>()
  const { teamContextValue } = useContext(TeamContext)
  const { result } = useEntityEndpoint<{ data: CompanyType[] } | null>(`/companies/${id}?teamNumber=${teamContextValue.teamNumber}`)
  const username = result?.data?.[0]?.CompanyNameText || result?.data?.[0]?.BestUrlText || ''

  return (
    <Page>
      <Heading step={step} actionProps={actionProps} />
      <Route
        exact
        path={`${Paths._companies}/:id/audit`}
        render={() => (
          <DynamicEntity<{ extraProps: { addprops: AddProps } }>
            addprops={{
              username,
              step,
              setStep,
              setActionProps
            }}
            url={`/companies/${id}/auditinfo?teamNumber=${teamContextValue.teamNumber}`}
            list
            component={CompanyAudit}
            empty="Profile not found"
            id="audit_profile"
          />
        )}
      />
      <Route path={`${Paths._companies}/:id/audit/tuples`} render={() => <TuplesListPage username={username} />} />
    </Page>
  )
}

export default CompanyAuditPages
