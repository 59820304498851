import React, { ReactElement, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Icon from '_shared/Icon'
import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import usePresetScroll from '_core/hooks/usePresetScroll'

import { ellipsis } from 'AppTheme'

const useStyles = makeStyles<{ underlined?: HeadingType['underlined']; link?: HeadingType['link'] }>()((theme, { link, underlined }) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    '&:first-of-type': {
      marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(1.5),
      borderBottom: underlined ? '0.6px #ECEEF0 solid' : '',
      marginBottom: theme.spacing(2)
    }
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '22px'
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'auto'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.secondary,
    letterSpacing: '0.15px',
    minHeight: 24,
    margin: 0,
    padding: 0,
    '& > span': {
      display: 'inline-block',
      ...ellipsis,
      maxWidth: link ? '80%' : '100%'
    }
  },
  widget: {
    fontSize: theme.spacing(2),
    marginBottom: 0,
    ...ellipsis
  },
  main: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'center'
  },
  icon: {
    borderRadius: '100%',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  mainIcon: {
    width: '30px',
    height: '30px'
  },
  count: {
    color: theme.palette.text.secondary
  },
  link: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
      marginBottom: theme.spacing(2)
    }
  }
}))

type HeadingType = {
  title: string | ReactNode
  count?: number
  icon?: IconProp
  link?: any
  underlined?: boolean
  action?: ReactElement | ReactNode | null
  variant?: 'none' | 'widget' | 'main'
  sidepanel?: SidepanelType
  className?: string
}

const Heading = ({ title, count, icon, link, variant = 'widget', underlined, action, sidepanel, className }: HeadingType) => {
  const { classes, cx } = useStyles({ underlined })

  return (
    <div className={cx({ [classes.wrapper]: variant === 'widget', [classes.mainWrapper]: variant === 'main' }, className)}>
      <div className={classes.heading}>
        {icon && <Icon mr={1} icon={icon} contained className={cx({ [classes.mainIcon]: variant === 'main' })} />}
        <Typography variant="h4" className={variant === 'none' ? classes.title : classes[variant as keyof typeof classes]} semiBold>
          {title}{' '}
          {typeof count === 'number' && count > 0 && (
            <>
              · <span className={classes.count}>{count}</span>
            </>
          )}
        </Typography>
      </div>
      {action}
      {typeof count === 'number' &&
        count >= 0 &&
        link &&
        (sidepanel ? (
          <SidepanelLink sidepanel={sidepanel} linkProps={{ to: link }}>
            {SeeAllBtn}
          </SidepanelLink>
        ) : (
          <Link to={link}>{SeeAllBtn}</Link>
        ))}
    </div>
  )
}

export const HeadingButton = (props: { title: string; icon?: ReactNode }) => {
  const { classes } = useStyles({})
  const wide = useWide()
  const { saveScroll } = usePresetScroll()

  const handleSeeAllClick = () => {
    saveScroll()
  }

  return (
    <Button
      onClick={handleSeeAllClick}
      variant={wide ? 'widget' : 'link'}
      endIcon={wide && props.icon}
      className={classes.link}
      disablePR={!wide}
      disablePY
    >
      {props.title}
    </Button>
  )
}

const SeeAllBtn = <HeadingButton title="See all" icon={<FontAwesomeIcon icon={['far', 'chevron-right']} style={{ fontSize: 14, marginLeft: 1 }} />} />

export default Heading
