import React, { useContext, useEffect, useState } from 'react'

import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import TupleSourcesPage from '_pages/people/[id]/audit/tuples/sources'

import { TeamContext } from '_core/context/TeamContext'

import InvalidTupleSuccessPage from '_core/components/audit/InvalidTupleSuccess'
import TuplesList, { Heading, TuplesProps } from '_core/components/audit/TuplesList'
import AudiTupleInvalidDialog from '_core/components/dialogs/AuditTupleInvalid'

import useDialog from '_core/hooks/useDialog'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const TuplesListPage = (props: { username: string }) => {
  const { id, keyword } = useParams<{ id: string } & SearchParams>()
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const history = useHistory()
  const match = useRouteMatch()
  const [checkedTuples, setCheckedTuples] = useState<string[]>([])
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  useEffect(() => {
    setMobileHeader('Audit tuples')
    setSubHeader(props.username)

    return () => setSubHeader('')
  }, [setMobileHeader, setSubHeader, props.username])

  const submitInvalid = async () => {
    const payload = checkedTuples.map((tuple) => {
      const [identity1, identity2] = tuple.split(' = ')
      return { identity1, identity2 }
    })
    await post<{ success: boolean; errorMessage?: string }>(mergeUrlWithParams('/people/splitmulti', { teamNumber: teamNumber.toString() }), payload)
    history.replace(`${match.url}/success`)
  }

  return (
    <>
      <Heading openDialog={openDialog} isDialogDisabled={!checkedTuples.length} />
      <DynamicEntity<{ extraProps: { addprops: Pick<TuplesProps, 'checkedTuples' | 'setCheckedTuples' | 'sourceBaseLink'> } }>
        url={mergeUrlWithParams(`/dataquality/people/${id}/tuples`, { teamNumber: teamNumber.toString() })}
        addprops={{ checkedTuples, setCheckedTuples, sourceBaseLink: `${Paths._people}/${id}/audit/tuples` }}
        keepMounted
        component={TuplesList}
        list
        infinite
        search
        empty="Tuples not found"
        emptySubtitle={keyword ? `No tuples found for your search '${keyword}'` : ''}
        id="person_tuples_list"
      />
      <AudiTupleInvalidDialog close={closeDialog} submit={submitInvalid} isOpened={isDialogOpened} />
    </>
  )
}

const baseUrl = `${Paths._people}/:id/audit/tuples`
const TuplesListPages = (props: { username: string }) => (
  <Switch>
    <Route exact path={baseUrl} render={() => <TuplesListPage username={props.username} />} />
    <Route exact path={`${baseUrl}/success`} component={InvalidTupleSuccessPage} />
    <Route exact path={`${baseUrl}/:tupleId1/:tupleId2`} component={TupleSourcesPage} />
  </Switch>
)

export default TuplesListPages
