import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(auto-fit, minmax(248px, 1fr))',
    alignItems: 'stretch',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    gap: theme.spacing(2)
  }
}))

const Action = ({ link, title, icon }: { link: string; title: string; icon: IconProp }) => {
  return (
    <SidepanelLink sidepanel={true} linkProps={{ to: link }}>
      <Button variant="widget" color="primary" startIcon={<FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} />} fullWidth>
        {title}
      </Button>
    </SidepanelLink>
  )
}

const baseUrl = DataAdminSubPaths.dataQuality
const items: {
  label: string
  icon: IconProp
  entity: DataQualityEntities
  description: string
}[] = [
  {
    label: 'People',
    icon: ['far', 'address-book'],
    description: 'Manage people related entity alignment issues.',
    entity: 'people'
  },
  {
    label: 'Companies',
    icon: ['far', 'building'],
    description: 'Manage companies related entity alignment issues.',
    entity: 'companies'
  }
]

const DataQualityActions = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      {items.map((item) => (
        <Widget scope="outlined" key={item.label}>
          <Box p={2}>
            <Heading title={item.label} icon={item.icon} underlined />
            {item.description && <Typography color="text.secondary">{item.description}</Typography>}
            <Box className={classes.buttons}>
              <Action title="Download report" icon={['far', 'file-download']} link={`${baseUrl}/${item.entity}/download`} />
              <Action title="Upload fixes" icon={['far', 'cloud-arrow-up']} link={`${baseUrl}/${item.entity}/upload`} />
            </Box>
          </Box>
        </Widget>
      ))}
    </Box>
  )
}

export default DataQualityActions
