import { useContext } from 'react'

import { useSnackbar } from 'notistack'

import { TeamContext } from '_core/context/TeamContext'

import GridHeaderButton from '_core/components/GridHeaderButton'

import useTeam from '_core/hooks/useTeam'

import { triggerDownloadXl } from 'utils/Utils'

const Download = (props: DownloadProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const { leadsList, leadType, disabled = false } = props
  const { enqueueSnackbar } = useSnackbar()
  const { getLeadsList } = useTeam()

  const handleIconClick = async () => {
    enqueueSnackbar('Analysis in progress. The download should begin shortly.', {
      variant: 'default'
    })

    try {
      const fetchedData = await getLeadsList(teamContextValue.teamNumber.toString(), leadType || 'Company', leadsList)
      if (fetchedData) {
        // Trigger a download
        triggerDownloadXl(fetchedData, leadType)
      } else {
        setTimeout(() => {
          enqueueSnackbar('Analysis complete. No matches found.', {
            variant: 'default'
          })
        }, 5000)
      }
    } catch (error) {
      enqueueSnackbar('Encountered error while processing request', {
        variant: 'error'
      })
      console.log('ERROR DURING DATA FETCH', error)
    }
  }

  return <GridHeaderButton hint="Download selected items" icon={['far', 'file-download']} onClick={handleIconClick} disabled={disabled} />
}

export default Download

type DownloadProps = {
  leadsList: string[]
  leadType: 'Company' | 'Person'
  disabled?: boolean
}
