import { SyntheticEvent } from 'react'

import { Box } from '@mui/material'
import moment from 'moment'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'

import Affiliation, { AffiliationPopover } from '_core/components/Affiliation'
import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import Heading from '_core/components/Heading'
import { Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'

import { dateFormat } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  list: {
    '& > .MuiBox-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      [theme.breakpoints.up('md')]: {
        '& > a > div > .MuiBox-root': {
          flex: 0
        }
      }
    }
  },
  informIcon: {
    marginLeft: theme.spacing(1)
  }
}))

type AffiliationType = {
  BestKnowerNameText: string
  BestUrlText: string
  CompanyMd5: string
  CorpLevelCompanyName: string
  JobCurrentAsOf: string
  PersonMd5: string
  PersonNameText?: string
  WeKnowEmployeeScore: number
  SeniorityLevel?: string
  IsFormerJob?: boolean
  TitleText?: string
  JobCompanyName?: string
}

export type InformAffiliationType = {
  companyName: string
  personName: string
  personId: string
  isFormerJob: boolean
  isInvalidJob?: boolean
  currentAsOf?: string
}

const Affiliations = (props: { loading: boolean; items?: AffiliationType[] }) => {
  const { classes } = useStyles()
  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<InformAffiliationType>()

  const handleOpenDialog = (e: SyntheticEvent, data: InformAffiliationType) => {
    e.stopPropagation()
    openDialog(data)
  }

  return (
    <Page variant="core">
      <Widget scope="list">
        <Wide>
          <Heading title="Affiliations" icon={['far', 'suitcase']} variant="main" />
        </Wide>

        <Repeater
          direction="vertical"
          variant="list"
          component={Affiliation}
          skeleton={{ size: 20, loading: props.loading }}
          className={classes.list}
          items={
            props.items?.map((job) => ({
              title: job.JobCompanyName || job.CorpLevelCompanyName,
              description: job.TitleText,
              blurb: `Last evidence on ${moment(job.JobCurrentAsOf).format(dateFormat)}`,
              sidepanel: true,
              link: `${Paths._companies}/${job.CompanyMd5}`,
              logoUrl: job.BestUrlText,
              icons: (
                <Box display="flex" alignItems="center">
                  {job.IsFormerJob && (
                    <AffiliationPopover
                      workExperience={{
                        companyIdentity: job.CompanyMd5,
                        companyName: job.JobCompanyName || job.CorpLevelCompanyName,
                        jobTitle: job.TitleText,
                        currentAsOf: job.JobCurrentAsOf || ''
                      }}
                      icon={['far', 'address-book']}
                    />
                  )}
                  <AffiliationInformDialog.TriggerEl
                    open={(e: SyntheticEvent) =>
                      handleOpenDialog(e, {
                        companyName: job.JobCompanyName || job.CorpLevelCompanyName,
                        personName: job.PersonNameText || 'No name',
                        personId: job.PersonMd5,
                        isFormerJob: !!job.IsFormerJob,
                        currentAsOf: job.JobCurrentAsOf || ''
                      })
                    }
                  />
                </Box>
              )
            })) || []
          }
          empty="No affiliations were found"
        />
      </Widget>
      <AffiliationInformDialog
        close={closeDialog}
        openSuccess={openSuccess}
        success={successMode}
        opened={!!openedDialog}
        {...(openedDialog || {})}
      />
    </Page>
  )
}

export default Affiliations
