import { useCallback } from 'react'

import moment from 'moment'

import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

const activitiesSaveData = {
  endpoint: '/usersettings/activitiesstats',
  getData: ({ from, to, stackColumns }: ActivityStatsPageParams): ActivityStatsInit => {
    return {
      fromDate: from || moment.utc().startOf('year').toISOString(),
      toDate: to || moment.utc().endOf('year').toISOString(),
      stackColumns: stackColumns === 'true' || false
    }
  }
}

const useActivitiesStatsUserSettings = () => {
  const { save } = useFilter()
  const { queryParams, updateQuery } = useSearchQuery<ActivityStatsPageParams>()

  const setInitial = useCallback(
    ({ fromDate: from, toDate: to, stackColumns }: ActivityStatsInit) => {
      updateQuery({ stackColumns: `${stackColumns}`, from, to })
    },
    [updateQuery]
  )

  const handleChange = (updates: typeof queryParams) => {
    save(activitiesSaveData, { ...queryParams, ...updates })
  }

  return {
    setInitial,
    handleChange
  }
}

export default useActivitiesStatsUserSettings
