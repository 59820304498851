import React from 'react'

import Page from '_shared/Page'

import Heading from '_core/components/Heading'
import { Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import RelationshipDetail from '_core/components/RelationshipDetail'
import Widget from '_core/components/Widget'

import Paths from 'Paths'

const Relationships = (props: any) => (
  <Page variant="core">
    <Wide>
      <Heading title="Key relationships" icon={['far', 'star']} variant="main" />
    </Wide>
    <Widget scope="list">
      <Repeater
        direction="vertical"
        variant="border"
        component={RelationshipDetail}
        skeleton={{ size: 8, loading: props.loading }}
        items={props.items?.map((rel: any) => ({
          name1: rel.BestKnowerNameText,
          link1: `${Paths._people}/${rel.BestKnowerUserKeyMd5}`,
          title1: rel.IntroducerBestJobTitleText,
          company1: rel.IntroducerBestJobMatchedCompanyName || rel.IntroducerBestJobCorpLevelCompanyName,
          companyId1: rel.IntroducerBestJobCompanyMd5,
          name2: rel.PersonNameText || rel.BestEmailAddrText,
          link2: `${Paths._people}/${rel.PersonMd5}`,
          title2: rel.TitleText,
          company2: rel.ContactBestJobMatchedCompanyName || rel.ContactBestJobCorpLevelCompanyName,
          companyId2: rel.ContactBestJobCompanyMd5,
          score: rel.BestUserKnowsPersonScore,
          userKey2: rel.BestEmailAddrText
        }))}
      />
    </Widget>
  </Page>
)

export default Relationships
