import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

interface INamePageProps {
  loading: boolean
  Aliases: any
  companyName: string
  setTotalAliases: any
}

const useStyles = makeStyles()((theme) => ({
  item: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 5px`
  },
  label: {
    paddingBottom: theme.spacing(1)
  }
}))

const NamePage = ({ loading, Aliases, companyName, setTotalAliases }: INamePageProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { classes } = useStyles()

  useEffect(() => setTotalAliases(Aliases?.total_item_count), [Aliases, setTotalAliases])

  const submitInvalid = async (identity: string, value: number) => {
    return post(mergeUrlWithParams('/companies/disqualify', { teamNumber: `${teamContextValue.teamNumber}` }), {
      identity,
      reason: nameReason[value]
    })
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 6, loading }}
      addprops={{ className: classes.item }}
      items={Aliases?.data?.map((name: any) => ({
        title: '',
        description: (
          <IdentifierLabel
            name={companyName}
            identity={name.CoNameAlias}
            type="CompanyName"
            className={classes.label}
            submit={submitInvalid}
            sourceLink={`${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`}
            auditLink={`${Paths._companies}/${id}/audit`}
          />
        ),
        blurb: name.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(name.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No aliases were found"
    />
  )
}

export default NamePage
