import React, { Component, ComponentProps, Fragment, PropsWithChildren } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Narrow, Wide } from './layout'

const useStyles = makeStyles()((theme) => ({
  icon: {
    padding: theme.spacing(1),
    borderRadius: '100%'
  },
  selectedIcon: {
    background: 'rgb(231, 246, 255)'
  },
  link: {
    fontSize: 12,
    padding: `${theme.spacing(2)} 0`,
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'center',
    maxWidth: '100%',
    '&:focus': {
      background: theme.palette.background.light
    },
    '& svg': {
      alignSelf: 'center'
    }
  },
  wrapper: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2)
    }
  }
}))

const currentItem = (links: any, id: any, location: any) => {
  const match = links.find(
    (link: any) => location.pathname === link.link || location.pathname.match(new RegExp(link.link + '/([0-9]+|name|email|phone|url)', 'ig'))
  )
  return (match && match.id === id) || (!match && id === 'dashboard')
}

const currentItemStyle = (currentItem: boolean) => ({
  color: currentItem ? '#167A99' : '#797979',
  background: currentItem ? '#fff' : ''
})

const Dashboard = <T,>(
  props: PropsWithChildren<
    {
      loading: boolean
      links: (props: T) => { id: string; name: string; link: string; condition: boolean | undefined; icon: string[] }[]
    } & T
  >
) => {
  const { classes, cx } = useStyles()
  const location = useLocation()

  const renderLinkContent = (link: any) => (
    <>
      {link.avatar ? (
        link.avatar
      ) : (
        <FontAwesomeIcon
          icon={link.icon}
          size="lg"
          className={cx([classes.icon, currentItem(props.links(props), link.id, location) && classes.selectedIcon])}
        />
      )}
      {link.name}
    </>
  )

  return (
    <>
      <Narrow>{props.children}</Narrow>
      <Wide>
        <Box style={{ background: '#fff', borderRadius: '4px' }}>
          <Grid container>
            <Grid item md={1}>
              <Box style={{ background: '#F8F9FB', height: '100%', marginRight: '16px' }}>
                {props
                  .links(props)
                  .filter((link) => link.condition)
                  .map((link) => (
                    <Fragment key={link.name}>
                      {props.loading && (
                        <span className={classes.link} style={currentItemStyle(currentItem(props.links(props), link.id, location))}>
                          {renderLinkContent(link)}
                        </span>
                      )}
                      {!props.loading && (
                        <Link
                          to={link.link}
                          onClick={(e) => location.pathname === link.link && e.preventDefault()}
                          className={classes.link}
                          style={currentItemStyle(currentItem(props.links(props), link.id, location))}
                        >
                          {renderLinkContent(link)}
                        </Link>
                      )}
                    </Fragment>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={11} className={classes.wrapper}>
              {props.children}
            </Grid>
          </Grid>
        </Box>
      </Wide>
    </>
  )
}

export default Dashboard
