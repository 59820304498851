import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'

import { Middle, Wide, Narrow, WideStrict } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  }
}))

const Audit = ({ name, type, menuItem }: { name: string; type: 'people' | 'companies'; menuItem?: boolean }) => {
  const { id } = useParams<{ id: string }>()
  const {
    classes: { wrapper },
    cx
  } = useStyles()

  return (
    <div className={cx({ [wrapper]: menuItem })}>
      <SidepanelLink linkProps={{ to: name ? `${type === 'people' ? Paths._people : Paths._companies}/${id}/audit` : '' }}>
        {type === 'people' && (
          <Wide>
            <WideStrict>
              <Button startIcon={<FontAwesomeIcon icon={['far', 'list-check']} style={{ fontSize: 14 }} />}>Audit</Button>
            </WideStrict>
            <Middle>
              <IconButton color="primary" hint="Audit" icon={['far', 'list-check']} style={{ fontSize: 14 }} loading={!name} />
            </Middle>
          </Wide>
        )}
        {type === 'companies' && (
          <Wide> 
            <Button startIcon={<FontAwesomeIcon icon={['far', 'list-check']} style={{ fontSize: 14 }} />}>Audit</Button>
          </Wide>
        )}
        <Narrow>
          {menuItem ? (
            <Box display="flex" alignItems="center">
              <FontAwesomeIcon icon={['far', 'list-check']} style={{ marginRight: 8, width: 24 }} />
              <Box>Audit</Box>
            </Box>
          ) : (
            <IconButton color="primary" hint="Audit" icon={['far', 'list-check']} style={{ fontSize: 14 }} loading={!name} />
          )}
        </Narrow>
      </SidepanelLink>
    </div>
  )
}

export default Audit
