import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Route, Switch, useParams, useLocation, matchPath, Redirect } from 'react-router-dom'

import ActivitiesPage from '_pages/companies/[id]/activities'
import ActivityStatsPages from '_pages/companies/[id]/activityStats'
import CSuite from '_pages/companies/[id]/c-suite'
import CSuitePage from '_pages/companies/[id]/c-suite/[id]'
import MarketDataPage from '_pages/companies/[id]/market-data'
import CompanyMarketDataPage from '_pages/companies/[id]/market-data/company'
import SimilarCompanies from '_pages/companies/[id]/similar'
import SimilarCompanyPage from '_pages/companies/[id]/similar/[id]'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import CompanyHeader from '_core/components/CompanyHeader'
import Dashboard from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import PrivateRoute from '_core/components/PrivateRoute'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import { useCSuite } from '_core/hooks/useCSuite'
import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { useSimilarCompanies } from '_core/hooks/useSimilarCompanies'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'

import { get } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import Aliases from './aliases'
import Contacts from './contacts'
import Interactions from './interactions'
import Introducers from './introducers'
import Profile from './profile'
import Relationships from './relationships'
import Touchpoints from './touchpoints'

type CompanyProfileType = {
  loading: boolean
  teamNumber: number
  marketDataIntegration?: MarketDataIntegration
  salesforce?: {
    isEnabled: true
  }
} & CompanyType

const url = Paths._companies

const CompanyProfile = ({ teamNumber, salesforce, marketDataIntegration, ...props }: CompanyProfileType) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<PersonProfilePageParams>()
  const { isExact: matchProfile } =
    matchPath(pathname, {
      path: Paths.companyProfile
    }) || {}
  const [interactions, setInteractions] = useState<{ [key: string]: any } | null>()
  const enableScroll = interactions !== undefined && !props.loading && !!props.CompanyMd5 && matchProfile
  usePresetScroll(enableScroll)

  const companyName = props.CompanyNameText || props.BestUrlText

  const enabledMarketData = marketDataIntegration?.enabled
  const prohibitedMarketData = typeof enabledMarketData === 'boolean' && !enabledMarketData
  const { marketData, getMarketData, error: marketDataError } = useCompanyMarketData(companyName, props.BestUrlText, true)
  const { similar, setSimilar, similarWidget, setSimilarWidget, getCompanyDetails } = useSimilarCompanies()
  const { cSuite, setCSuite, getPersonDetails } = useCSuite()

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile } = dataVisibility || {}

  const activitiesPath = `${url}/:id/activities`
  const { isExact: matchCompanyActivities = false } = matchPath(pathname, { path: activitiesPath }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([id, matchCompanyActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  const dashboardLinks = (props: any) =>
    [
      {
        id: 'dashboard',
        name: 'Dashboard',
        link: `${url}/${props.CompanyMd5}`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'th']
      },
      {
        id: 'activityStats',
        name: 'Activity statistics',
        link: `${url}/${props.CompanyMd5}/activityStats`,
        condition: isStatsWidgetVisible,
        icon: ['far', 'chart-simple']
      },
      {
        id: 'introducers',
        name: 'Introducers',
        link: `${url}/${props.CompanyMd5}/introducers`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'address-book']
      },
      {
        id: 'interactions',
        name: 'Interactions',
        link: `${url}/${props.CompanyMd5}/interactions`,
        condition: showInteractionsInProfile,
        icon: ['far', 'envelope']
      },
      {
        id: 'contacts',
        name: 'Contacts',
        link: `${url}/${props.CompanyMd5}/contacts`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'user']
      },
      {
        id: 'touchpoints',
        name: 'Touchpoints',
        link: `${url}/${props.CompanyMd5}/touchpoints`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'handshake']
      },
      {
        id: 'relationships',
        name: 'Key Relationships',
        link: `${url}/${props.CompanyMd5}/relationships`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'star']
      },
      {
        id: 'aliases',
        name: 'Aliases and domains',
        link: `${url}/${props.CompanyMd5}/aliases`,
        condition: props.loading || props.CompanyMd5,
        icon: ['far', 'fingerprint']
      }
    ].filter(({ condition }) => condition)

  useEffect(() => {
    if (matchProfile) {
      setMobileHeader(!props.loading ? queryParams.name || companyName : '', props.loading)
    }
  }, [companyName, setMobileHeader, matchProfile, props.loading, queryParams.name])

  useEffect(() => {
    return () => {
      setInteractions(undefined)
      setSimilarWidget(null)
      setCSuite(null)
    }
  }, [props.CompanyMd5])

  useEffect(() => {
    const getInteractions = async () => {
      setInteractions(await get(`/interactions?TeamNumber=${teamNumber}&companyMd5=${props.CompanyMd5}`))
    }

    if (matchProfile && !props.loading && interactions === undefined) {
      if (showInteractionsInProfile) {
        getInteractions()
      } else {
        setInteractions(null)
      }
    }
  }, [interactions, matchProfile, props.CompanyMd5, showInteractionsInProfile, props.loading, teamNumber])

  useEffect(() => {
    if (!props.loading && enabledMarketData && !marketData && companyName) {
      getMarketData(teamNumber)
    }
  }, [enabledMarketData, marketData, props.loading, companyName, teamNumber])

  const interactionsHeight = dashboardLinks(props).filter((link: { [key: string]: any }) => link.condition).length * 78

  if (!props.loading && !props.CompanyMd5) {
    return <Empty title="Profile not found" closeExtension />
  }

  const Header = (
    <CompanyHeader marketData={marketData} marketDataIntegration={marketDataIntegration} isSalesforceEnabled={salesforce?.isEnabled} {...props} />
  )

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          {Header}
        </Route>
        <Route exact path={`${url}/:id/*`}>
          <Wide>{Header}</Wide>
        </Route>
      </Switch>

      <Dashboard {...props} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile
              interactions={interactions}
              marketData={marketData}
              similar={similarWidget}
              setSimilar={setSimilarWidget}
              cSuite={cSuite}
              setCSuite={setCSuite}
              teamNumber={teamNumber}
              marketDataIntegration={marketDataIntegration}
              showInteractionsInProfile={!!showInteractionsInProfile}
              showActivityStats={!!isStatsWidgetVisible}
              isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
              {...props}
            />
          </Route>

          <PrivateRoute
            hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
            exact
            path={activitiesPath}
            render={() => <ActivitiesPage companyName={companyName} />}
          />
          <PrivateRoute
            hasAccess={isStatsWidgetVisible}
            path={`${url}/:id/activityStats`}
            render={() => <ActivityStatsPages companyName={companyName} />}
          />
          <Route path={`${url}/:id/relationships`}>
            <DynamicEntity
              url={`/companies/${id}/people?TeamNumber=${teamNumber}&SortBy=ScoreDesc&employeeType=Current`}
              component={Relationships}
              list={true}
              infinite={true}
              nativeBack
              subHeader={(props: any) => (
                <Typography title={`Key relationships · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                  Key relationships · {props.total_item_count}
                </Typography>
              )}
              id="company_relationships"
            />
          </Route>

          <Route path={`${url}/:id/introducers`} component={Introducers} />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={`/companies/${id}?teamNumber=${teamNumber}&includeStats=true`}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap>
                    Key touch points
                  </Typography>
                )}
                id="company_touchpoints"
              />
            )}
          />
          <Route path={`${url}/:id/contacts`} component={Contacts} />
          <Route path={`${url}/:id/aliases`} render={() => <Aliases companyName={companyName} />} />
          <Route exact path={`${url}/:id/similar`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarCompanies
                website={props.BestUrlText}
                marketData={marketData}
                similar={similar || similarWidget}
                setSimilar={setSimilar}
                teamNumber={teamNumber}
                loading={props.loading}
              />
            )}
          </Route>
          <Route exact path={`${url}/:id/similar/:similarId`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarCompanyPage getCompanyDetails={getCompanyDetails} teamNumber={teamNumber} />
            )}
          </Route>
          <Route exact path={`${url}/:id/c-suite`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <CSuite website={props.BestUrlText} loading={props.loading} people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} />
            )}
          </Route>
          <Route exact path={`${url}/:id/c-suite/:cSuiteId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CSuitePage getPersonDetails={getPersonDetails} teamNumber={teamNumber} />}
          </Route>
          <Route exact path={`${url}/:id/market-data`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <MarketDataPage marketData={marketData} error={marketDataError} />}
          </Route>
          <Route exact path={`${url}/:id/market-data/company/:companyId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CompanyMarketDataPage teamNumber={teamNumber} />}
          </Route>
          <PrivateRoute hasAccess={showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <Interactions minHeight={interactionsHeight} CompanyMd5={props.CompanyMd5} />
          </PrivateRoute>
        </Switch>
      </Dashboard>
    </>
  )
}

const CompanyPage = (props: any) => {
  const { id } = props.match.params
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const memoUrls = useMemo(
    () => [
      {
        key: 'profile',
        url: `/companies/${id}?teamNumber=${teamNumber}&numIntroducers=5&numEmployees=7&numUrls=4&numAliases=4&includeStats=true&excludeFormerRoles=true&WithCompanyTags=true&NumCrmSourceKeys=3`,
        merge: true,
        single: true
      },
      {
        key: 'salesforce',
        url: '/usersettings/salesforce'
      },
      {
        key: 'marketDataIntegration',
        url: '/usersettings/marketDataIntegration'
      }
    ],
    [id, teamNumber]
  )

  return (
    <Page>
      <DynamicEntity<{ extraProps: { addprops: Pick<CompanyProfileType, 'teamNumber'> } }>
        urls={memoUrls}
        component={CompanyProfile}
        addprops={{ teamNumber }}
        empty="Profile not found"
        keepMounted
        id="company_profile"
      />
    </Page>
  )
}

export default CompanyPage
