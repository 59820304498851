import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import TupleSourcesList from '_core/components/audit/TupleSourcesList'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const TupleSourcesPage = () => {
  const { tupleId1, tupleId2 } = useParams<{ id: string; tupleId1: string; tupleId2: string } & SearchParams>()
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { isAdminOrCurator } = useAdminOrCuratorCheck()

  useEffect(() => {
    setMobileHeader('Audit tuple sources')
    setSubHeader(`${decodeURIComponent(tupleId1)} associated with ${decodeURIComponent(tupleId2)}`)

    return () => setSubHeader('')
  }, [setMobileHeader, setSubHeader, tupleId1, tupleId2])

  return (
    <Page>
      <DynamicEntity
        component={TupleSourcesList}
        url={isAdminOrCurator ? `/dataquality/persontuplesources/${tupleId1}/to/${tupleId2}?teamNumber=${teamNumber}` : ''}
        list
        infinite
        id="person_tuple_sources_list"
      />
    </Page>
  )
}

export default TupleSourcesPage
