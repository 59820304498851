import React, { useCallback, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import AddPeople from '_core/components/AddPrivilegedPeople'
import { PersonOptionType } from '_core/components/dialogs/CreatePersonOptionDialog'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import { useLookUpPeople } from '_core/hooks/useLookup'
import useSidebar from '_core/hooks/useSidebar'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import { DataAdminSubPaths } from 'Paths'

type OptionalFields = {
  personEmail: string
  personName: string
  lastModified: string
  viaWhomEmail: string
}

export type PrivilegedContact = {
  rowNumber: number
  teamNumber: number
  personMd5: string
  whitelisted: boolean
  total: number
  idText?: string
} & (OptionalFields | { [K in keyof OptionalFields]: never })

const AddPrivilegedContactsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Add contacts')
  }, [setMobileHeader])

  return (
    <Narrow>
      <Topbar nativeBack />
      <Component />
    </Narrow>
  )
}

const Component = () => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const history = useHistory()
  const { close: sidebarClose } = useSidebar()
  const { updateParent } = useSidepanelPayloads()
  const { lookupPeople, forceAbort } = useLookUpPeople({
    ColleagueFlag: 'External'
  })

  const loadOptions = useCallback(
    async (searchTerm) => {
      const result = await lookupPeople(searchTerm)
      if (result) {
        return result.map(({ PersonNameText, BestEmailAddrText }) => ({ name: PersonNameText || BestEmailAddrText, email: BestEmailAddrText }))
      }
    },
    [lookupPeople]
  )

  const sidepanel = isSidepanel()

  const handleSave = async (addedContacts: PersonOptionType[]) => {
    if (addedContacts.length) {
      const payload = addedContacts.map((contact) => ({
        emailAddress: contact.email,
        name: contact.name
      }))
      await post(`/people/privwhitelistpeople?teamNumber=${teamNumber}`, payload)
    }

    if (sidepanel) {
      sidebarClose()
      updateParent({ action: 'RELOAD_LIST', value: 'privileged-companies' })
    } else {
      history.push(`${DataAdminSubPaths.privilegedCompanies}/contacts`)
    }
  }

  return <AddPeople handleSave={handleSave} sidepanel={sidepanel} loadOptions={loadOptions} forceAbort={forceAbort} />
}

export default AddPrivilegedContactsPage
