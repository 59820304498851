import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'

import AuditProvider from '_core/context/Audit'

import AuditReview from '_core/components/audit/AuditReview'
import AuditSuccess from '_core/components/audit/AuditSuccess'
import AuditTuples from '_core/components/audit/AuditTuples'
import AuditEmpty from '_core/components/audit/Empty'
import AuditIdentifiers from '_core/components/audit/PeopleAuditIdentifiers'
import LeaveDialog from '_core/components/dialogs/Leave'
import InnerDialog from '_core/components/InnerDialog'
import Widget from '_core/components/Widget'

import useAuditEntities from '_core/hooks/useAuditEntities'
import useAuditSubmit from '_core/hooks/useAuditSubmit'
import useAuditTuples from '_core/hooks/useAuditTuples'
import useDialog from '_core/hooks/useDialog'
import usePicker from '_core/hooks/usePicker'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export type ActionProps = { isDirty: boolean; handleReset: () => void; onSearchClick: () => void } | undefined

export type AddProps = {
  username: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  setActionProps: Dispatch<SetStateAction<ActionProps>>
}

const Component = ({ step, setStep, setActionProps, ...props }: { username: string; loading: boolean } & AddProps) => {
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)
  const [loading, setLoading] = useState<boolean>(false)
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const [searchOpened, setSearchOpened] = useState<boolean>(false)

  const { isDirty, reset, valid, invalid, setActive, primary } = useAuditEntities()
  const { tuples, splits, requestTuples } = useAuditTuples()

  const submit = useAuditSubmit({ entity: 'people', invalid, valid, splits })
  const handleClose = useSidepanelClose()

  const handleReset = useCallback(() => {
    reset()
    setStep(0)
  }, [reset])

  const openSearch = useCallback(() => {
    handleReset()
    setSearchOpened(true)
  }, [reset])

  const closeSearch = () => {
    setSearchOpened(false)
  }

  const onSearchClick = isDirty ? openDialog : openSearch

  const { Picker: Search } = usePicker({
    entity: 'people',
    onSearchClick,
    getLink: (md5: string) => `${Paths._people}/${md5}/audit`
  })

  useEffect(() => {
    setMobileHeader('Audit identities')
    setSubHeader(props.username)

    return () => setSubHeader('')
  }, [setMobileHeader, setSubHeader, props.username])

  useEffect(() => {
    return () => {
      handleReset()
      setActionProps(undefined)
    }
  }, [setActionProps, handleReset])

  useEffect(() => {
    setActionProps({ isDirty, handleReset, onSearchClick })
  }, [isDirty, handleReset, onSearchClick])

  const next = (step = 1) => {
    if (primary) {
      setActive(primary)
      setStep((prev) => prev + step)
    }
  }

  const back = (step = 1) => {
    setStep((prev) => prev - step)
  }

  const openTuples = async () => {
    setLoading(true)
    const tuplesGroups = await requestTuples('people')
    next(tuplesGroups?.length === 1 || !tuplesGroups?.[1].length ? 2 : 1)
    setLoading(false)
  }

  const reviewGoBack = () => {
    back(tuples?.length === 1 || !tuples?.[1].length ? 2 : 1)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const isOk = await submit()
    if (isOk) {
      setStep(3)
    }
    setLoading(false)
  }

  if (loading) {
    return (
      <Box py={14} display="flex" justifyContent="center">
        <CircularProgress size="24px" color="secondary" />
      </Box>
    )
  }

  return (
    <>
      <Widget scope="list">
        {searchOpened ? (
          <InnerDialog close={closeSearch}>{Search}</InnerDialog>
        ) : (
          <>
            {step === 0 && <AuditIdentifiers userName={props.username} loading={props.loading} next={openTuples} reset={handleReset} />}
            {step === 1 && <AuditTuples next={next} back={back} reset={handleReset} />}
            {step === 2 && <AuditReview submit={handleSubmit} back={reviewGoBack} reset={handleReset} />}
            {step === 3 && <AuditSuccess openSearch={openSearch} label="Choose another person" close={handleClose} />}
          </>
        )}
      </Widget>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={openSearch} />
    </>
  )
}

const PersonAudit = (props: { items: Partial<AuditInfo>; loading: boolean } & AddProps) => {
  const { items: auditInfo, loading, ...addProps } = props

  if (props.loading || (auditInfo.anchorMd5 && auditInfo.entities)) {
    return (
      <AuditProvider auditInfo={auditInfo as AuditInfo}>
        <Component loading={loading} {...addProps} />
      </AuditProvider>
    )
  }

  return <AuditEmpty />
}

export default PersonAudit
